<template>
    <div>
        <h3>Schedule Editor</h3>
        <hr>
        <b-button @click="addSchedule" variant="outline-success">
            <b-icon icon="calendar-plus"></b-icon> Add
        </b-button>
        <p></p>
        <div class="text-center mb-3"
             style="max-width: 60rem"
        >
            <b-table
                striped
                hover
                responsive="true"
                :items="items"
                :fields="fields"
                style="overflow-y: hidden"
            >
                <template #cell(status)="row">
                    <b-icon-cloud-check v-if="items[row.index].stat"></b-icon-cloud-check>
                    <b-icon-cloud-slash v-else></b-icon-cloud-slash>
                </template>
                <template #cell(edit)="row">
                    <b-icon-pencil-fill @click="editItem(row.index)"></b-icon-pencil-fill>
                </template>
                <template #cell(duplicate)="row">
                    <b-icon-stickies-fill @click="dupItem(row.index)"></b-icon-stickies-fill>
                </template>
                <template #cell(delete)="row">
                    <b-icon-trash-fill @click="delItem(row.index)"></b-icon-trash-fill>
                </template>
            </b-table>
            <hr>
            <b-button @click="saveSchedule" variant="outline-primary">
                <b-icon icon="cloud-arrow-up"></b-icon> Save
            </b-button>
            <b-modal
                id="edit-sched"
                ref="modal"
                centered
                style="min-width: 30rem"
                :title="addEditTitle"
                @show="resetEditModal"
                @hidden="resetEditModal"
                @ok="handleEditOk"
            >
                <template #modal-footer>
                    <b-container>
                        <b-row cols="5">
                            <b-col cols=""></b-col>
                            <b-button
                                variant="success"
                                @click="handleEditOk"
                            >
                                Save
                            </b-button>
                            <b-col></b-col>
                            <b-button
                                variant="danger"
                                @click="handleEditCancel"
                            >
                                Cancel
                            </b-button>
                        </b-row>
                    </b-container>
                </template>
                <b-form ref="form" class="text-center" @submit.stop.prevent="handleEditSubmit">
                    <b-form-group>
                        <b-form-checkbox-group
                            v-model="selDays"
                            :options="dayChecks"
                            class="mb-3"
                            value-field="item"
                            text-field="name">
                        </b-form-checkbox-group>
                        <b-container>
                            <b-row cols="3">
                                <b-col>
                                    <b-button @click="freqPreset(1)">Everyday</b-button>
                                </b-col>
                                <b-col>
                                    <b-button @click="freqPreset(2)">Weekdays</b-button>
                                </b-col>
                                <b-col>
                                    <b-button @click="freqPreset(3)">Weekends</b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container>
                            <b-row cols="2">
                                <b-col class="mb-3">
                                    <label style="max-width: 4rem">Start:</label>
                                </b-col>
                                <b-col cols="5">
                                    <b-form-input id="type-time" type="time" v-model="startDate"></b-form-input>
                                </b-col>
                                <b-col class="mb-3">
                                    <label style="max-width: 4rem">End:</label>
                                </b-col>
                                <b-col cols="5">
                                    <b-form-input id="type-time" type="time" v-model="endDate"></b-form-input>
                                </b-col>
                                <b-col>
                                    <label style="max-width: 4rem">Mode:</label>
                                </b-col>
                                <b-col cols="5">
                                    <b-form-select
                                        v-model="modeSelected"
                                        :options="modeOptions"
                                    ></b-form-select>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container v-if="modeSelected === 1">
                            <b-row cols="3">
                                <b-col>
                                    <label>Cycle:</label>
                                </b-col>
                                <b-col>
                                    <label>On Time:</label>
                                    <b-form-input v-model="onTime"></b-form-input>
                                    <label>Sec(s)</label>
                                </b-col>
                                <b-col >
                                    <label>Off Time:</label>
                                    <b-form-input v-model="offTime"></b-form-input>
                                    <label>Sec(s)</label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <hr>
                        <b-container>
                            <b-row cols="3">
                                <b-col cols="6">
                                    <b-form-checkbox
                                        id="chkTemp"
                                        v-model="useTemp"
                                        name="chkTemp"
                                        value=true
                                        unchecked-value=false
                                    >Enable Temperature Control</b-form-checkbox>
                                </b-col>
                                <b-col cols="5">
                                    <b-form-input  v-model="tempF"></b-form-input>
                                </b-col>
                                <b-col cols="1">
                                    <label>F</label>
                                </b-col>
                            </b-row>
                        </b-container>
                        <div v-if="numZones===2">
                            <hr>
                            <b-form-checkbox-group
                                v-model="selZones"
                                :options="zoneChecks"
                                value-field="item"
                                text-field="name">
                            </b-form-checkbox-group>
                        </div>
                    </b-form-group>
                </b-form>
            </b-modal>
        </div>
    </div>
</template>

<script>
import eTypes from "@/enumTypes";

export default {
    name: "SchCoolMistEditor",
    data() {
        return {
            devId: null,
            items: [],
            fields: [
                'status',
                'frequency',
                'start',
                'end',
                'mode',
                'temperature',
                'zone',
                'edit',
                'duplicate',
                'delete'
            ],
            modeSelected: 0,
            modeOptions: [
                {value: 0, text: "Continuous"},
                {value: 1, text: "Cycle"}
            ],
            useTemp: false,
            tempF: 85,
            onTime: 30,
            offTime: 30,
            selItem: 0,
            addEdit: 0,
            addEditTitle: "Add",
            selDays: [],
            dayChecks: [
                {item: 0, name: "Su"},
                {item: 1, name: "Mo"},
                {item: 2, name: "Tu"},
                {item: 3, name: "We"},
                {item: 4, name: "Th"},
                {item: 5, name: "Fr"},
                {item: 6, name: "Sa"}
            ],
            selZones: [],
            zoneChecks: [],
            startDate: '12:00',
            endDate: '13:00',
            intTime: 45,
            numZones: 2,
            schedRef: null
        }
    },
    created() {
        this.devId = this.$route.params.id;
        this.schedRef = this.$store.getters.deviceSched.slice();
        this.zoneChecks = [];
        this.zoneChecks.push({item: 0, name: "Zone 1", disabled: false });
        this.numZones = this.$store.getters.deviceCfg.numZones;
        if (this.numZones === 2) {
            this.zoneChecks.push({item: 1, name: "Zone 2", disabled: false });
        }
        this.updateSched();
    },
    methods: {
        saveSchedule() {
            let sch = [];
            let changeCount = 0;
            for (let i = 0; i < this.schedRef.length; i++) {
                sch.push({
                    time: this.schedRef[i].time,
                    endTime: this.schedRef[i].endTime,
                    freq: this.schedRef[i].freq,
                    eType: this.schedRef[i].eType,
                    duration: this.schedRef[i].duration,
                    interval: this.schedRef[i].interval,
                    gmtOffset: this.schedRef[i].gmtOffset,
                    startOffset: this.schedRef[i].startOffset,
                    startFlag: this.schedRef[i].startFlag,
                    daysFlag: this.schedRef[i].daysFlag,
                    useTemp: this.schedRef[i].useTemp,
                    tempF: this.schedRef[i].tempF,
                    zone: this.schedRef[i].zone,
                });
                if (this.schedRef[i].stat === false) {
                    changeCount++;
                }
            }
            if (changeCount > 0 || this.schedRef.length !== this.$store.getters.deviceSched.length) {
                this.$store.dispatch('setDeviceSchedule', {
                    id: this.devId,
                    sch: sch
                }).then(() => {
                    this.$store.commit('storeDeviceSched', sch);
                    this.$router.back();
                });
            } else {
                this.$router.back();
            }
        },
        addSchedule() {
            this.addEdit = 0;
            this.selDays = [0, 1, 2, 3, 4, 5, 6];
            this.addEditTitle = "Add";
            this.$refs['modal'].show();
        },
        freqPreset(presetNum) {
            if (presetNum === 1) {
                this.selDays = [0, 1, 2, 3, 4, 5, 6];
            }
            else if (presetNum === 2) {
                this.selDays = [1, 2, 3, 4, 5];
            }
            else if (presetNum === 3) {
                this.selDays = [0,6];
            }
        },
        editItem(itemIndex) {
            //console.log(itemIndex);
            this.selItem = itemIndex;
            this.addEdit = 1;
            this.addEditTitle = "Edit";
            const curSch = this.schedRef[this.selItem];
            this.selDays = [];
            if ((curSch.daysFlag & eTypes.enums.Days.SUNDAY) === eTypes.enums.Days.SUNDAY)
                this.selDays.push(0);
            if ((curSch.daysFlag & eTypes.enums.Days.MONDAY) === eTypes.enums.Days.MONDAY)
                this.selDays.push(1);
            if ((curSch.daysFlag & eTypes.enums.Days.TUESDAY) === eTypes.enums.Days.TUESDAY)
                this.selDays.push(2);
            if ((curSch.daysFlag & eTypes.enums.Days.WEDNESDAY) === eTypes.enums.Days.WEDNESDAY)
                this.selDays.push(3);
            if ((curSch.daysFlag & eTypes.enums.Days.THURSDAY) === eTypes.enums.Days.THURSDAY)
                this.selDays.push(4);
            if ((curSch.daysFlag & eTypes.enums.Days.FRIDAY) === eTypes.enums.Days.FRIDAY)
                this.selDays.push(5);
            if ((curSch.daysFlag & eTypes.enums.Days.SATURDAY) === eTypes.enums.Days.SATURDAY)
                this.selDays.push(6);

            this.startDate = this.moment(this.getLocalOffset(curSch.time, curSch.gmtOffset) * 1000).format("HH:mm");
            this.endDate = this.moment(this.getLocalOffset(curSch.endTime, curSch.gmtOffset) * 1000).format("HH:mm");

            if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CONTINUOUS)
                this.modeSelected = 0;
            else if (curSch.startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CYCLE)
                this.modeSelected = 1;

            this.onTime = curSch.duration;
            this.offTime = curSch.interval;

            if (curSch.useTemp) {
                this.useTemp = true;
                this.tempF = curSch.tempF;
            }
            else
            {
                this.useTemp = false;
                this.tempF = this.$store.getters.deviceCfg.tempDefault;
            }

            if (curSch.zone === 1)
                this.selZones = [0];
            else if (curSch.zone === 2)
                this.selZones = [1];
            else if (curSch.zone === 3)
                this.selZones = [0, 1];

            this.$refs['modal'].show();
        },
        dupItem(itemIndex) {
            let dupEntry = {
                time: this.schedRef[itemIndex].time,
                endTime: this.schedRef[itemIndex].endTime,
                freq: this.schedRef[itemIndex].freq,
                eType: this.schedRef[itemIndex].eType,
                duration: this.schedRef[itemIndex].duration,
                interval: this.schedRef[itemIndex].interval,
                gmtOffset: this.schedRef[itemIndex].gmtOffset,
                startOffset: this.schedRef[itemIndex].startOffset,
                startFlag: this.schedRef[itemIndex].startFlag,
                daysFlag: this.schedRef[itemIndex].daysFlag,
                useTemp: this.schedRef[itemIndex].useTemp,
                tempF: this.schedRef[itemIndex].tempF,
                zone: this.schedRef[itemIndex].zone,
                stat: false
            }
            this.schedRef.push(dupEntry);
            this.updateSched();
        },
        delItem(itemIndex) {
            this.schedRef.splice(itemIndex, 1);
            this.updateSched();
        },
        getLocalOffset(timestamp, altOffset) {
            const geoData = this.$store.getters.deviceGeo;
            let totalOffset = geoData.gmtOffset;
            let orgDst = this.moment(timestamp * 1000).isDST();
            let curDst = this.moment().isDST();
            let useLocal = false;
            if (Math.abs(Math.abs(altOffset) - Math.abs(geoData.gmtOffset)) > 1) {
                altOffset = totalOffset;
                useLocal = true;
            }
            if (altOffset !== 0) {
                if (altOffset !== geoData.gmtOffset) {
                    totalOffset = geoData.gmtOffset - (geoData.gmtOffset - altOffset);
                }
            }
            if (orgDst !== curDst) {
                if (!orgDst && curDst)
                    totalOffset += 1;
                else if (orgDst && !curDst)
                    totalOffset -= 1;
            }
            if (useLocal) {
                timestamp += ((totalOffset * 60) * 60);
                let mn = this.moment(timestamp * 1000);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
            else {
                let mn = this.moment(timestamp * 1000).utcOffset(totalOffset * 60);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
        },
        resetEditModal() {

        },
        // eslint-disable-next-line no-unused-vars
        handleEditOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();

            // Check first if time is valid
            let startCheck = parseInt(this.moment(this.startDate, 'HH:mm').format('X'));
            let endCheck = parseInt(this.moment(this.endDate, 'HH:mm').format('X'));
            if (startCheck > endCheck) {
                this.$bvToast.toast('End time has to be after Start time', {
                    title: 'Schedule Edit',
                    variant: 'danger',
                    solid: true
                });
                return;
            }

            // Trigger submit handler
            this.handleEditSubmit();
        },
        // eslint-disable-next-line no-unused-vars
        handleEditCancel(bvModalEvt) {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sched')
            });
        },
        handleEditSubmit() {
            const sysGmtOffset = this.$store.getters.deviceGeo.gmtOffset;
            let editIdx = this.selItem;
            if (this.addEdit === 0) { // Add new Entry
                let newEntry = {
                    time: Date.now() / 1000,
                    endTime: Date.now() / 1000,
                    useTemp: false,
                    tempF: 85,
                    freq: 4,
                    eType: 1,
                    duration: 30,
                    interval: 30,
                    gmtOffset: sysGmtOffset,
                    startOffset: 0,
                    startFlag: eTypes.enums.Event_Start_Time.EVENT_START_TIME_CONTINUOUS,
                    daysFlag: 127,
                    zone: 0
                }
                this.schedRef.push(newEntry);
                editIdx = this.schedRef.length - 1;
            }
            this.schedRef[editIdx].stat = false;
            this.schedRef[editIdx].gmtOffset = sysGmtOffset;
            let daysFlag = 0;
            for (let i = 0; i < this.selDays.length; i++) {
                daysFlag += (1 << this.selDays[i]);
            }
            this.schedRef[editIdx].daysFlag = daysFlag;

            this.schedRef[editIdx].time = parseInt(this.moment(this.startDate, 'HH:mm').format('X'));
            this.schedRef[editIdx].endTime = parseInt(this.moment(this.endDate, 'HH:mm').format('X'));

            if (this.modeSelected === 0)
                this.schedRef[editIdx].startFlag = eTypes.enums.Event_Start_Time.EVENT_START_TIME_CONTINUOUS;
            else if (this.modeSelected === 1)
                this.schedRef[editIdx].startFlag = eTypes.enums.Event_Start_Time.EVENT_START_TIME_CYCLE;

            this.schedRef[editIdx].duration = this.onTime;
            this.schedRef[editIdx].interval = this.offTime;

            if (this.useTemp) {
                this.schedRef[editIdx].useTemp = this.useTemp;
                this.schedRef[editIdx].tempF = this.tempF;
            }

            this.schedRef[editIdx].zone = 0;
            if (this.selZones.length > 0) {
                for (let i = 0; i < this.selZones.length; i++) {
                    this.schedRef[editIdx].zone += (this.selZones[i] + 1);
                }
            }
            else {
                this.schedRef[editIdx].zone = 0;
            }

            this.updateSched();

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('edit-sched')
            });
        },
        updateSched() {
            this.items = [];
            const sch = this.schedRef;
            for (let i = 0; i < sch.length; i++) {
                let sItem = {};
                if (i % 2 === 0) {
                    sItem._rowVariant = 'warning';
                }
                // else {
                //     sItem._rowVariant = 'secondary';
                // }
                if (sch[i].daysFlag === eTypes.enums.Days.WEEKENDS_ONLY) {
                    sItem.frequency = "Weekends";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.WEEKDAYS_ONLY) {
                    sItem.frequency = "Weekdays";
                }
                else if (sch[i].daysFlag === eTypes.enums.Days.EVERYDAY) {
                    sItem.frequency = "Everyday";
                }
                else {
                    sItem.frequency = "Custom";
                }
                sItem.start = this.moment(this.getLocalOffset(sch[i].time, sch[i].gmtOffset) * 1000).format("h:mm A");
                sItem.end = this.moment(this.getLocalOffset(sch[i].endTime, sch[i].gmtOffset) * 1000).format("h:mm A");

                // eslint-disable-next-line no-prototype-builtins
                if (sch[i].hasOwnProperty('stat')) {
                    sItem.stat = sch[i].stat;
                } else {
                    sItem.stat = true;
                }

                if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CONTINUOUS)
                    sItem.mode = "Continuous";
                else if (sch[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CYCLE) {
                    sItem.mode = "";
                    sItem.mode += sch[i].duration + " S On, ";
                    sItem.mode += sch[i].interval + " S Off";
                }

                if (sch[i].useTemp)
                    sItem.temperature = sch[i].tempF;
                else
                    sItem.temperature = "Off";

                if (sch[i].zone === 0)
                    sItem.zone = "0"
                else if (sch[i].zone === 1)
                    sItem.zone = "1";
                else if (sch[i].zone === 2)
                    sItem.zone = "2";
                else if (sch[i].zone === 3)
                    sItem.zone = "1 & 2";

                this.items.push(sItem);
            }
        }
    }
}
</script>

<style scoped>

</style>
