<template>
  <div class="newPass">
    <b-form-group class="container">
      <b-card bg-variant="light">
        <h3 class="col-12 text-center">Enter New Password</h3>
        <b-form-group>
          <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-5" label="Password" label-for="input-5">
              <b-form-input type="password" id="input-5" placeholder="Password" v-model="form.password" :state="passMatch" required></b-form-input>
              <p></p>
              <label >Re-Enter Password</label>
              <b-form-input type="password" id="input-6" placeholder="Re-Enter Password" v-model="form.password2" :state="passMatch" required></b-form-input>
              <b-form-invalid-feedback :state="passMatch">
                Your password must be 8-20 characters long, contain letters, numbers and special characters and must not
                contain spaces.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="passMatch">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
            <b-button class="col-12 text-center" type="submit" variant="primary">Submit</b-button>
          </b-form>
        </b-form-group>
      </b-card>
    </b-form-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        password: '',
        password2: ''
      }
    }
  },
  computed: {
    passMatch() {
      return this.passCheck();
    }
  },
  methods: {
    passCheck() {
      let match = false;
      if (this.form.password.length >= 8 && this.form.password2.length >= 8) {
        if (this.form.password === this.form.password2) {
          let re1 = /[A-Za-z]+/;
          let re2 = /\d/;
          if (re1.test(this.form.password) && re2.test(this.form.password)) {
            return true;
          } else {
            return false;
          }
        }
      }
      return match;
    },
    onSubmit() {
      if (!this.passCheck()) {
        this.$bvToast.toast('Fix Password', {
            title: 'Login',
            variant: 'danger',
            solid: true
        });
        return;
      }
      console.log("Submit");
      this.$http.post('users/resetPass?reset=' + this.$route.query.reset, {newPass: this.form.password}).then(response => {
        return response.json();
        // eslint-disable-next-line no-unused-vars
      }).then(res => {
          this.$bvToast.toast('Password has been reset, you are now logged in', {
              title: 'Login',
              variant: 'success',
              solid: true
          });
        this.$store.dispatch('updateLogin', res);
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
          this.$bvToast.toast('Error Resetting Password, Try starting over!', {
              title: 'Login',
              variant: 'danger',
              solid: true
          });
      });
    }
  }
}
</script>

<style scoped>
.container {
  background-color: #f3f3f3;
  color: #663399;
  padding: 15px 25px;
  width: 100%;
  max-width: 35rem;
  position: relative;
  transform: translate(0%, 5%);
  left: 0%;
  top: 50%;
}
</style>
