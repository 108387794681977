import { render, staticRenderFns } from "./ResetNewPass.vue?vue&type=template&id=32ded1cd&scoped=true&"
import script from "./ResetNewPass.vue?vue&type=script&lang=js&"
export * from "./ResetNewPass.vue?vue&type=script&lang=js&"
import style0 from "./ResetNewPass.vue?vue&type=style&index=0&id=32ded1cd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ded1cd",
  null
  
)

export default component.exports