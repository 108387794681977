<template>
  <div class="container">
    <div id="signin">
      <div class="card-header pt-4 pb-4 text-center">
        <span><img class="imglogo" src="../assets/SmartMist_Logo.png" alt="" height="200"></span>
      </div>
      <div class="text-center w-75 m-auto">
        <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Sign In</h4>
        <p class="text-muted mb-4">Enter your username and password.</p>
      </div>
      <div class="form-group">
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" autocomplete="username" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Enter your username" v-model="username">
          </div>
          <div class="form-group">
            <b class="text-muted float-right"><small><router-link to="/resetpass">Forgot your password?</router-link></small></b>
            <label for="password">Password</label>
            <input type="password" autocomplete="current-password" class="form-control" id="password" placeholder="Enter your password" v-model="password">
          </div>
          <div class="form-group mb-0 text-center">
            <button type="submit" class="btn btn-primary">Sign In</button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <p class="text-muted">Don't have an account? <router-link to="/signup"><b>Sign Up</b></router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
    created() {
      if (this.$store.getters.isPb) {
          setTimeout(() => {
              this.$store.commit("clearPb");
          }, 2000);
      }
    },
    methods: {
    onSubmit () {
      const formData = {
        username: this.username,
        password: this.password,
      }
      this.$store.dispatch('login', formData);
    }
  }
}
</script>

<style scoped>
.imglogo {
  width: 100%;
  height: 100%;
  max-width: 30rem;
  position: relative;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 50%;
}
.container {
  background-color: #f3f3f3;
  color: #663399;
  padding: 15px 25px;
  width: 100%;
  max-width: 25rem;
  position: relative;
  transform: translate(0%, 10%);
  left: 0%;
  top: 50%;
}
</style>
