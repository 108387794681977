<template>
  <div class="passReset">
    <b-form-group class="container">
      <b-card bg-variant="light">
        <div class="text-center w-75 m-auto">
          <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Password Reset</h4>
          <p class="text-muted mb-4">Enter your username or email.</p>
        </div>
        <b-form-group>
          <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-3" label="Username" label-for="input-3">
              <b-form-input id="input-3" v-model="form.username" placeholder="Username"></b-form-input>
            </b-form-group>
            <div>
              <h5 class="col-12 text-center">OR</h5>
            </div>
            <b-form-group id="input-group-4" label="Email" label-for="input-4">
              <b-form-input id="input-4" v-model="form.email" type="email" placeholder="Enter email"></b-form-input>
            </b-form-group>
            <b-button class="col-12 text-center" type="submit" variant="primary">Submit</b-button>
          </b-form>
        </b-form-group>
      </b-card>
    </b-form-group>
      <vue-recaptcha
          ref="invisibleRecaptcha"
          @verify="onVerify"
          @expired="onExpired"
          size="invisible"
          :sitekey="sitekey"
          :loadRecaptchaScript="true"
      >
      </vue-recaptcha>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import cfg from '../config'
export default {
  data() {
    return {
      form: {
        username: '',
        email: ''
      },
        sitekey: cfg.settings.siteKey
    }
  },
    components: {
        VueRecaptcha
    },
  computed: {
    emailCheck() {
      return this.emailTest();
    }
  },
  methods: {
      onVerify: function (response) {
          if (response) {
              let formData = {};
              if (this.form.email.length > 0) {
                  formData.email = this.form.email;
              }
              if (this.form.username.length > 0) {
                  formData.username = this.form.username;
              }

              this.$http.post('users/resetPassInit', formData).then(response => {
                  return response.json();
                  // eslint-disable-next-line no-unused-vars
              }).then(res => {
                  this.$router.replace('/resetpassinit');
                  // eslint-disable-next-line no-unused-vars
              }).catch(err => {
                  this.$bvToast.toast('Unrecognized Email Address', {
                      title: 'Reset Password',
                      variant: 'danger',
                      solid: true
                  });
              });
          }
      },
      onExpired: function () {
          //console.log('Expired')
          this.$router.push("/");
      },
    onSubmit() {
      //console.log("Submit");
      if (this.form.username.length === 0 && this.form.email.length === 0) {
          this.$bvToast.toast('Need to provide either a username or an email', {
              title: 'Reset Password',
              variant: 'danger',
              solid: true
          });
        return;
      } else if (this.form.username.length > 0 && this.form.email.length > 0) {
          this.$bvToast.toast('Please provide only a username or an email, not both', {
              title: 'Reset Password',
              variant: 'danger',
              solid: true
          });
        return;
      }

        this.$refs.invisibleRecaptcha.execute();

    },
    emailTest() {
      if (this.form.email.length < 7) return false

      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
      if (!re.test(this.form.email)) {
        return false;
      } else {
        return true;
      }
    },
  }
}
</script>

<style scoped>
.container {
  background-color: #f3f3f3;
  color: #663399;
  padding: 15px 25px;
  width: 100%;
  max-width: 35rem;
  position: relative;
  transform: translate(0%, 10%);
  left: 0%;
  top: 50%;
}
</style>
