
exports.settings = {
    //apiUrl: "http://localhost:3000/", // local testing
    //apiUrl: "http://api.richardwardlow.com:5190/",
    //apiUrl: "https://devtest-13554.nodechef.com/", // test deployment
    apiUrl: "https://smartmist-13800.nodechef.com/", // Prod deployment
    //apiUrl: "https://circuitinteractive.com/app/",
    //socketUrl: "http://api.richardwardlow.com:5190/"
    siteKey: "6LfSVWUaAAAAAEGTJcVzfi1kUBVcVOCRGPvEPmPC" // app.smart-mist
}
