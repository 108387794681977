<template>
    <div>
  <b-form-group class="signup">
    <b-form-group class="container">
      <b-card bg-variant="light">
        <h3 class="col-12 text-center">Sign Up</h3>
        <b-form-group>
          <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-1" label="First Name" label-for="input-1">
              <b-form-input id="input-1" v-model="form.firstname" placeholder="First Name" required ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Last Name" label-for="input-2">
              <b-form-input id="input-2" v-model="form.lastname" placeholder="Last Name" required ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" label="Username" label-for="input-3">
              <b-form-input id="input-3" autocomplete="username" v-model="form.username" placeholder="Username" required ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-4" label="Email" label-for="input-4">
              <b-form-input id="input-4" v-model="form.email" type="email" placeholder="Enter email" required></b-form-input>
              <b-form-invalid-feedback :state="emailCheck">
                Please enter a valid Email address
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="emailCheck">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
            <b-form-group id="input-group-5" label="Password" label-for="input-5">
              <b-form-input type="password" autocomplete="new-password"  id="input-5" placeholder="Password" v-model="form.password" :state="passMatch" required></b-form-input>
              <p></p>
              <label >Re-Enter Password</label>
              <b-form-input type="password" autocomplete="new-password" id="input-6" placeholder="Re-Enter Password" v-model="form.password2" :state="passMatch" required></b-form-input>
              <b-form-invalid-feedback :state="passMatch">
                Your password must be 8-20 characters long, contain letters, numbers and special characters and must not
                contain spaces.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="passMatch">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
              <b-button class="col-12 text-center" type="submit" variant="primary">Submit</b-button>
          </b-form>
        </b-form-group>
      </b-card>
    </b-form-group>
    </b-form-group>
        <vue-recaptcha
            ref="invisibleRecaptcha"
            @verify="onVerify"
            @expired="onExpired"
            size="invisible"
            :sitekey="sitekey"
            :loadRecaptchaScript="true"
        >
        </vue-recaptcha>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import cfg from '../config'
export default {
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        password2: ''
      },
        sitekey: cfg.settings.siteKey
    }
  },
    components: {
      VueRecaptcha
    },
  computed: {
    passMatch() {
      return this.passCheck();
    },
    emailCheck() {
      return this.emailTest();
    }
  },
    methods: {
        onVerify: function (response) {
            //console.log('Verify: ' + response);
            if (response) {
                this.$http.post('users/signup', this.form).then(response => {
                    return response.json();
                    // eslint-disable-next-line no-unused-vars
                }).then(res => {
                    this.$router.replace('/signupdone');
                    // eslint-disable-next-line no-unused-vars
                }).catch(err => {
                    this.$bvToast.toast('Username or Email already in use or invalid', {
                        title: 'Login',
                        variant: 'danger',
                        solid: true
                    });
                });
            }
        },
        onExpired: function () {
            //console.log('Expired')
            this.$router.push("/");
        },
    emailTest() {
      if (this.form.email.length < 7) return false

      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
      if (!re.test(this.form.email)) {
        return false;
      } else {
        return true;
      }
    },
    passCheck() {
      let match = false;
      if (this.form.password.length >= 8 && this.form.password2.length >= 8) {
        if (this.form.password === this.form.password2) {
          let re1 = /[A-Za-z]+/;
          let re2 = /\d/;
          if (re1.test(this.form.password) && re2.test(this.form.password)) {
            return true;
          } else {
            return false;
          }
        }
      }
      return match;
    },
    onSubmit() {
      if (!this.passCheck()) {
          this.$bvToast.toast('Fix Password', {
              title: 'Signup',
              variant: 'danger',
              solid: true
          });
        return;
      }
      if (!this.emailTest()) {
          this.$bvToast.toast('Fix Email', {
              title: 'Signup',
              variant: 'danger',
              solid: true
          });
        return;
      }
        this.$refs.invisibleRecaptcha.execute();
    }
  }
}
</script>

<style scoped>
.container {
  background-color: #f3f3f3;
  color: #663399;
  padding: 15px 25px;
  width: 100%;
  max-width: 35rem;
  position: relative;
  transform: translate(0%, 5%);
  left: 0%;
  top: 50%;
}
</style>
