<template>
<div>
    <div class="text-center">
        <h3>Temporary Password Login</h3>
    </div>
    <hr>
    <b-container>
        <b-row align-h="center">
            <div class="text-center">
                <b-card header="Change Your Password">
                    <b-form-group>
                        <b-form @submit.prevent="onSubmit">
                            <b-form-group id="input-group-1" label="Old Password" label-for="input-1">
                                <b-form-input type="password" id="input-1" placeholder="Password" v-model="form.oldPass" required></b-form-input>
                            </b-form-group>
                            <b-form-group id="input-group-5" label="New Password" label-for="input-5">
                                <b-form-input type="password" id="input-5" placeholder="New Password" v-model="form.newPass1" :state="passMatch" required></b-form-input>
                                <p></p>
                                <label >Re-Enter New Password</label>
                                <b-form-input type="password" id="input-6" placeholder="Re-Enter New Password" v-model="form.newPass2" :state="passMatch" required></b-form-input>
                                <b-form-invalid-feedback :state="passMatch">
                                    Your password must be 8-20 characters long, contain letters, numbers and special characters and must not
                                    contain spaces.
                                </b-form-invalid-feedback>
                                <b-form-valid-feedback :state="passMatch">
                                    Looks Good.
                                </b-form-valid-feedback>
                            </b-form-group>
                            <b-button class="col-12 text-center" type="submit" variant="primary">Submit</b-button>
                        </b-form>
                    </b-form-group>
                </b-card>
            </div>
        </b-row>
    </b-container>
</div>
</template>

<script>
export default {
    name: "ChangePassword",
    data() {
        return {
            form: {
                oldPass: '',
                newPass1: '',
                newPass2: ''
            }
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        passCheck() {
            let match = false;
            if (this.form.newPass1.length >= 8 && this.form.newPass2.length >= 8) {
                if (this.form.newPass1 === this.form.newPass2) {
                    let re1 = /[A-Za-z]+/;
                    let re2 = /\d/;
                    if (re1.test(this.form.newPass1) && re2.test(this.form.newPass2)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            return match;
        },
        onSubmit() {
            if (!this.passCheck()) {
                this.$bvToast.toast('Fix Password', {
                    title: 'Login',
                    variant: 'danger',
                    solid: true
                });
                return;
            }
            this.$store.dispatch('changePassword', {
                oldPass: this.form.oldPass,
                newPass: this.form.newPass1
            }).then(() => {
                this.$bvToast.toast('Password Changed', {
                    title: 'Change Password',
                    variant: 'success',
                    solid: true
                });
            }).catch(() => {
                this.$bvToast.toast('Change Password Error', {
                    title: 'Change Password',
                    variant: 'danger',
                    solid: true
                });
            });
        }
    },
    computed: {
        passMatch() {
            return this.passCheck();
        }
    }
}
</script>

<style scoped>

</style>
