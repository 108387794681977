import Vue from 'vue'
import VueResource from "vue-resource";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import moment from 'moment'
import '@/plugins/echarts';
import '@/plugins/calendars';

const cfg = require('./config');

import App from './App.vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueResource);

// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.prototype.moment = moment

import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
Vue.http.options.root = cfg.settings.apiUrl;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
