<template>
  <div>
    <h3>Device Dashboard</h3>
    <hr>
    <div v-if="devices.length === 1">

    </div>
    <div v-else-if="devices.length > 1">
      <app-devices></app-devices>
    </div>
    <div v-else>
      <h4>No Devices</h4>
    </div>

  </div>
</template>

<script>
import Devices from "@/components/Devices";
import roles from '@/roles';
import Vue from "vue";

export default {
  created() {
    if (!this.$store.state.idToken) {
      this.$router.push('/');
    } else {
        //console.log(this.$store.getters.regStatus);
        if (this.$store.getters.regStatus === 0) {
            this.$router.push('/');
        }
        else if (this.$store.getters.regStatus === 2) {
            this.$router.push('/changepass');
        }
        else if (this.$store.getters.actStatus !== 0) {
            this.$router.push('/');
        }
        else {
            this.$store.dispatch('getPubSubKeys');
        }
      this.$store.commit('clearDeviceData');
      this.$store.dispatch('getUserDeviceList');
    }
  },
  updated() {
    const devList = this.$store.getters.devices;
    if (devList.length === 0) {
      if (this.$store.getters.role >= roles.roles.technician) {
        this.$router.push('/admin');
      }
    }
    else if (devList.length === 1) {
        Vue.http.get('udev/sys/type?id=' + devList[0].id).then(response => {
            return response.json();
        }).then(res => {
            this.$store.commit('setCurDevType', res.sysType);
        }).catch(err => {
            console.log(err);
        });

      this.$router.push('/device/' + devList[0].id);
    }
  },
  components: {
    appDevices: Devices
  },
  computed: {
    userRole() {
      return this.$store.getters.role;
    },
    devices() {
      return this.$store.getters.devices;
    }
  }
}
</script>

<style scoped>

</style>
