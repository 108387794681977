import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "@/components/Home";
import Dashboard from "@/components/Dashboard";
import Login from "@/components/Login";
import Signup from "@/components/Signup";
import ResetPass from "@/components/ResetPass";
import SettingsUser from "@/components/SettingsUser";
import SignupSuccess from "@/components/SignupSuccess";
import SignupVerify from "@/components/SignupVerify";
import SignupResend from "@/components/SignupResend";
import ResetPassInit from "@/components/ResetPassInit";
import ResetNewPass from "@/components/ResetNewPass";
import ResetPassVerify from "@/components/ResetPassVerify";
import DeviceDetails from "@/components/DeviceDetails";
import ScheduleEditor from "@/components/ScheduleEditor";
import SuspendEditor from "@/components/SuspendEditor";
import ActivityDetails from "@/components/ActivityDetails";
import ChangePassword from "@/components/ChangePassword";
import SchCoolMistEditor from "@/components/SchCoolMistEditor";
import ActivityDetailsCoolMist from "@/components/ActivityDetailsCoolMist";

Vue.use(VueRouter)

const routes = [
    {path: '/', component: Login},
    {path: '/Home', component: Home},
    {path: '/signup', component: Signup},
    {path: '/changepass', component: ChangePassword},
    {path: '/signupdone', component: SignupSuccess},
    {path: '/signupresend', component: SignupResend},
    {path: '/email/verify', component: SignupVerify},
    {path: '/settings/user', component: SettingsUser},
    {path: '/resetpass', component: ResetPass},
    {path: '/device/:id', component: DeviceDetails},
    {path: '/pass/verifyReset', component: ResetPassVerify},
    {path: '/resetnewpass', component: ResetNewPass},
    {path: '/resetpassinit', component: ResetPassInit},
    {path: '/activity/:id', component: ActivityDetails},
    {path: '/activity/coolmist/:id', component: ActivityDetailsCoolMist},
    {path: '/schedule/edit/:id', component: ScheduleEditor},
    {path: '/schedule/coolmist/edit/:id', component: SchCoolMistEditor},
    {path: '/suspend/edit/:id', component: SuspendEditor},
    {path: '/dashboard', component: Dashboard},
    {
      path: "/admin",
      component: () =>
        import("./components/AdminDash")
    },
    {
        path: "/admin/user/create",
        component: () =>
            import("./components/AdminModules/Users/CreateUser")
    },
    {
        path: "/admin/dev/debug/:id",
        component: () =>
            import("./components/AdminModules/AdminDeviceDebug")
    },
    {
        path: "/admin/dev/new/config/:id",
        component: () =>
            import("./components/AdminModules/Devices/ConfigureNewDevice")
    },
    {
        path: "/admin/editor/templates",
        component: () =>
            import("./components/AdminModules/General/TemplateEditor")
    },
    {
        path: "/admin/editor/companies",
        component: () =>
            import("./components/AdminModules/General/CompanyEditor")
    },
    {
        path: "/admin/editor/config/:id",
        component: () =>
            import("./components/AdminModules/AdminEditSysConfig")
    },
    {
        path: "/admin/editor/location/:id",
        component: () =>
            import("./components/AdminModules/AdminEditLocation")
    },
    {
        path: "/admin/editor/devusers/:id",
        component: () =>
            import("./components/AdminModules/AdminEditDevUsers")
    },
    {
        path: "/admin/editor/schedule/:id",
        component: () =>
            import("./components/AdminModules/AdminSpraySchedule")
    },
    {
        path: "/admin/editor/schedule/coolmist/:id",
        component: () =>
            import("./components/AdminModules/AdminSpraySchCoolMist")
    },
    {
        path: "/admin/editor/suspend/:id",
        component: () =>
            import("./components/AdminModules/AdminSusSchedule")
    },
    {
        path: "/admin/view/activity/:id",
        component: () =>
            import("./components/AdminModules/AdminActivities")
    },
    {
        path: "/admin/dev/dashboard/:id",
        component: () =>
            import("./components/DeviceDetailsOther")
    },
    {
        path: "/admin/view/activity/coolmist/:id",
        component: () =>
            import("./components/AdminModules/AdminActivitiesCoolMist")
    },
    {
        path: "/admin/replacedevice",
        component: () =>
            import("./components/AdminModules/SwapDevices")
    },
    {
        path: "/admin/fw/manager",
        component: () =>
            import("./components/AdminModules/FwManager")
    }
];

export default new VueRouter({mode: 'history', routes})
