<template>
    <div class="text-center mb-2"
         style="max-width: 60rem; min-width: 20rem;"
    >
        <b-col md="auto">
            <b-card class="md-3">
                <h6 class="font-weight-bold">Local Info For:</h6>
                <h6>{{address}}</h6>
                <hr>
                <b-table striped hover responsive="true" no-border-collapse :items="items" :fields="fields">

                </b-table>
            </b-card>
        </b-col>
    </div>
</template>

<script>
export default {
    name: "GeoData",
    props: ['id'],
    data () {
        return {
            items: [],
            fields: [
                'ignore',
                "field",
                "--/--/----"
            ]
        }
    },
    computed: {
        address() {
            return this.$store.getters.deviceGeo.street;
        }
    },
    created() {
        this.updateGeo();
    },
    methods: {
        updateGeo() {
            const geo = this.$store.getters.deviceGeo;

            this.items = [];
            this.fields[2] = this.moment(Date.now()).format("MM/DD/YYYY");
            let tItem1 = {};
            tItem1.ignore = '';
            tItem1.field = 'Sunrise';
            tItem1[this.fields[2]] = this.moment(geo.sunrise * 1000).format("h:mm A");
            tItem1._rowVariant = 'success';
            this.items.push(tItem1);
            let tItem2 = {};
            tItem2.ignore = '';
            tItem2.field = 'Sunset';
            tItem2[this.fields[2]] = this.moment(geo.sunset * 1000).format("h:mm A");
            //tItem2._rowVariant = 'secondary';
            this.items.push(tItem2);
            let tItem3 = {};
            tItem3.ignore = '';
            tItem3.field = 'Temp Low';
            tItem3[this.fields[2]] = geo.tempLow + " F";
            tItem3._rowVariant = 'success';
            this.items.push(tItem3);
            let tItem4 = {};
            tItem4.ignore = '';
            tItem4.field = 'Temp High';
            tItem4[this.fields[2]] = geo.tempHigh + " F";
            //tItem4._rowVariant = 'secondary';
            this.items.push(tItem4);
            // let tItem4b = {};
            // tItem4b.ignore = '';
            // tItem4b.field = 'Current Temp';
            // tItem4b[this.fields[2]] = geo.curTemp + " F";
            // this.items.push(tItem4b);
            let tItem5 = {};
            tItem5.ignore = '';
            tItem5.field = 'Rain Chance';
            if (geo.rainChance === 0)
                tItem5[this.fields[2]] = "---";
            else
                tItem5[this.fields[2]] = geo.rainChance + " %";
            tItem5._rowVariant = 'success';
            this.items.push(tItem5);
            let tItem6 = {};
            tItem6.ignore = '';
            tItem6.field = 'Wind';
            if (geo.windSpeed === 0)
                tItem6[this.fields[2]] = "---";
            else
                tItem6[this.fields[2]] = geo.windSpeed + " MPH";
            //tItem6._rowVariant = 'secondary';
            this.items.push(tItem6);
        }
    }
}
</script>

<style scoped>

</style>
