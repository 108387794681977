<template>
  <div>
    <b-col>
      <b-card
          title="SmartMist"
          header-tag="header"
          footer-tag="footer"
          style="max-width: 20rem"
          class="mb-2"
      >
          <b-card-sub-title>{{deviceType}}</b-card-sub-title>
          <b-card-body>
              <b-card-text>
                  <p class="text-sm-center">{{sysName}}</p>
              </b-card-text>
            <b-card-text>
                <b-icon icon="geo-alt"></b-icon>
                {{location}}
            </b-card-text>
            <b-button @click="gotoDetails" block variant="primary">Details</b-button>
          </b-card-body>
        <template #footer>
          <small class="text-muted">ID: {{device.id}}</small>
        </template>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Device",
  props: ['device'],
  data() {
      return {
          location: '',
          sysName: '',
          devType: ''
      }
  },
  methods: {
    gotoDetails() {
        this.$store.commit('setCurDevType', this.devType);
        this.$router.push('/device/' + this.device.id);
    }
  },
    computed: {
      deviceType() {
          if (this.devType === 'CoolMist')
              return "Cool Mist Sprayer";
          else
              return "Insect Control Sprayer";
        }
    },
    created() {
        Vue.http.get('udev/sys/type?id=' + this.device.id).then(response => {
            return response.json();
        }).then(res => {
            this.devType = res.sysType;
        }).catch(err => {
            console.log(err);
        });

        Vue.http.get('udev/sys/name?id=' + this.device.id).then(response => {
            return response.json();
        }).then(res => {
            this.sysName = res.sysName;
        }).catch(err => {
            console.log(err);
        });
        Vue.http.get('ugeo/loc/street?id=' + this.device.id).then(response => {
            return response.json();
        }).then(res => {
            this.location = res.street;
        }).catch(err => {
            console.log(err);
        });
    }
}
</script>

<style scoped>

</style>
