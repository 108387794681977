<template>
<div>
    <h2>Email Verification</h2>
    <hr>
    <p></p>
    <b-container>
        <b-row align-h="center">
            <div class="text-center">
                <b-card header="Checking Email Verification">
                    <div>
                        Your Email: <h5><b-badge :variant="varType"> is {{verifyTest}} </b-badge></h5>
                    </div>
                    <p></p>
                    <div v-if="isVerified">
                        You may <b-button variant="primary" @click="$router.push('/')">Login</b-button> now
                    </div>
                </b-card>
            </div>
        </b-row>
    </b-container>
</div>
</template>

<script>
export default {
  data() {
    return {
      verifyTest: "not verified",
      isVerified: false,
        varType: 'danger'
    }
  },
  created() {
    this.checkEmail();
  },
  methods: {
    checkEmail() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.query.hasOwnProperty('reg')) {
        //console.log(this.$route.query);
        this.$http.get('users/verify?reg=' + this.$route.query.reg).then(response => {
          if (response.status === 200) {
            this.verifyTest = "verified";
            this.isVerified = true;
            this.varType = 'success';
            this.gotoLoginPageTimeout();
          } else if (response.status === 208) {
            this.verifyTest = "already verified";
            this.isVerified = true;
              this.varType = 'success';
            this.gotoLoginPageTimeout();
          }
        });
      }
    },
    gotoLoginPageTimeout() {
      const router = this.$router;
      setTimeout(function(){
        router.replace('/');
        }, 3000);
    }
  }
}
</script>

<style scoped>

</style>
