import Vue from 'vue'
import Vuex from 'vuex'
import router from "./router";
import PubNub from "pubnub";
import roles from '@/roles';

Vue.use(Vuex);

const store = new Vuex.Store( {
    state: {
        idToken: null,
        userId: null,
        user: null,
        role: null,
        logoutTimer: null,
        status: null,
        actStat: null,
        devices: [],
        userData: [],
        customerData: [],
        employeeData: [],
        associateData: [],
        devData: [],
        companyList: [],
        adminCustTablePerPage: 5,
        adminAsscTablePerPage: 5,
        adminEmplTablePerPage: 5,
        adminDevTablePerPage: 5,
        adminFwPerPage: 5,
        adminFwDevPerPage: 5,
        cfg: null,
        sys: null,
        compList: null,
        devInfo: null,
        otherDevInfo: null,
        devUsers: null,
        aSelectedUser: null,
        aSelectedDev: null,
        aSelectedGeo: null,
        aSelectCompTemplate: 0,
        sched: null,
        sus: null,
        geo: null,
        activityLog: null,
        activityDetailLog: null,
        devLogs: null,
        devSync: false,
        actSync: false,
        schedSyncTime: 0,
        schedSyncCode: 1,
        susSyncTime: 0,
        susSyncCode: 1,
        activeDevice: null,
        currentChannel: null,
        lastDevicePing: 0,
        deviceOnline: false,
        sprayStatus: false,
        refreshActivity: false,
        templateSchedules: [],
        selectedTemplateSchedules: [],
        curDevFwVer: null,
        lastSocketCmd: '',
        swapDevOld: null,
        swapDevNew: null,
        deviceFwList: [],
        selectedDevFw: null,
        fwListInsect: [],
        fwListCoolMist: [],
        fwListPicWd: [],
        curDevType: "",
        selectedFw: null,
        pb: null
    },
    mutations: {
        authUser(state, userData) {
            state.idToken = userData.token;
            state.userId = userData.userId;
            state.status = userData.status;
            state.actStat = userData.actStat;
            state.role = userData.role;
            state.comp = userData.comp;
        },
        storeUser (state, user) {
            state.user = user;
        },
        clearAuthData (state) {
            state.idToken = null;
            state.userId = null;
            state.status = null;
            state.actStat = null;
            state.role = null;
            state.comp = null;
            if (state.logoutTimer) {
                clearTimeout(state.logoutTimer);
            }
        },
        storeDevices (state, devices) {
            state.devices = devices;
        },
        clearDeviceData (state) {
            state.devices = [];
            state.cfg = null;
            state.sys = null;
            state.devInfo = null;
            state.sched = null;
            state.sus = null;
            state.geo = null;
            state.compList = null;
            state.activityLog = null;
            state.actSync = false;
            state.devSync = false;
        },
        clearDeviceDataOther (state) {
            state.cfg = null;
            state.sys = null;
            state.otherDevInfo = null;
            state.sched = null;
            state.sus = null;
            state.aSelectedGeo = null;
            state.activityLog = null;
            state.actSync = false;
            state.devSync = false;
        },
        clearPb(state) {
            state.pb = null;
        },
        clearAdminUserData (state) {
          state.userData = [];
        },
        storeDeviceCfg (state, cfg) {
            state.cfg = cfg;
        },
        storeDeviceSys (state, sys) {
            state.sys = sys;
        },
        storeCompList (state, cList) {
          state.compList = cList;
        },
        storeTemplateCompSel(state, comp) {
          state.aSelectCompTemplate = comp;
        },
        storeDeviceInfo (state, info) {
            state.devInfo = info;
        },
        storeOtherDevInfo (state, info) {
            state.otherDevInfo = info;
        },
        storeDeviceSched (state, sched) {
            state.sched = sched;
        },
        storeDeviceSus (state, sus) {
            state.sus = sus;
        },
        storeDeviceGeo (state, geo) {
            state.geo = geo;
        },
        setDeviceSync (state, sync) {
            state.devSync = sync;
        },
        setActSync (state, sync) {
            state.actSync = sync;
        },
        storeDeviceUsers (state, userList) {
            state.devUsers = userList;
        },
        storeActivityLog (state, actLog) {
            state.activityLog = actLog;
        },
        storeActivityDetailLog (state, actLog) {
          state.activityDetailLog = actLog;
        },
        setSchedSyncStatus (state, stat) {
            //console.log(stat);
            state.schedSyncTime = stat.stat.date;
            state.schedSyncCode = stat.stat.sync;
        },
        setSusSyncStatus (state, stat) {
            //console.log(stat.stat.sync);
            state.susSyncTime = stat.stat.date;
            state.susSyncCode = stat.stat.sync;
        },
        setSocket(state, sock) {
            state.sock = sock;
        },
        setActiveDevice(state, devId) {
            state.activeDevice = devId;
        },
        setDeviceMode(state, mode) {
          state.devInfo.mode = mode;
        },
        setDeviceOnlineStatus(state, status) {
            state.deviceOnline = status;
        },
        setLastDevicePing(state, datetime) {
            state.lastDevicePing = datetime;
        },
        setSprayStatus(state, sprayStat) {
            state.sprayStatus = sprayStat;
        },
        setRefreshActivity(state, refreshStat) {
            state.refreshActivity = refreshStat;
        },
        setLogoutTimer(state, logTimer) {
            state.logoutTimer = logTimer;
        },
        setAdminUserList(state, userList) {
            state.userData = userList;
            state.customerData = [];
            state.employeeData = [];
            state.associateData = [];
            for (let i = 0; i < userList.length; i++) {
                if (userList[i].role === roles.roles.normalUser) {
                    state.customerData.push(userList[i]);
                }
                else if (userList[i].role === roles.roles.technician ||
                    userList[i].role === roles.roles.admin ||
                    userList[i].role === roles.roles.accountManager ||
                    userList[i].role === roles.roles.superUser
                ) {
                    state.employeeData.push(userList[i]);
                }
                else if (userList[i].role === roles.roles.guestUser) {
                    state.associateData.push(userList[i]);
                }
            }
        },
        setAdminDevList(state, devList) {
            state.devData = devList;
        },
        storeDevLogs(state, devLogs) {
            state.devLogs = devLogs.data;
        },
        storeAdminSelectedUser(state, user) {
            state.aSelectedUser = user;
        },
        storeAdminSelectedDevice(state, dev) {
            state.aSelectedDev = dev;
        },
        storeAdminSelectedGeo(state, geo) {
            state.aSelectedGeo = geo;
        },
        storeTemplateSchedules(state, tmpSch) {
            state.templateSchedules = tmpSch;
        },
        addTemplateSchedule(state, newSch) {
          state.templateSchedules.push(newSch);
        },
        storeSelectedTemplates(state, selSch) {
            state.selectedTemplateSchedules = selSch;
        },
        storeCurDevFwVer(state, curFw) {
            state.curDevFwVer = curFw;
        },
        storeLastSocketCmd(state, cmd) {
            state.lastSocketCmd = cmd;
        },
        storeSwapDevOld(state, devId) {
            state.swapDevOld = devId;
        },
        storeSwapDevNew(state, devId) {
            state.swapDevNew = devId;
        },
        storeDeviceFwList(state, fwDevList) {
            state.deviceFwList = fwDevList;
        },
        storeSelectedDevFw(state, devFw) {
            state.selectedDevFw = devFw;
        },
        storeFwListInsect(state, fwList) {
            for (let i = 0; i < fwList.length; i++) {
                fwList[i].deviceType = "InsectControl";
            }
            state.fwListInsect = fwList;
        },
        storeFwListCoolMist(state, fwList) {
            for (let i = 0; i < fwList.length; i++) {
                fwList[i].deviceType = "CoolMist";
            }
            state.fwListCoolMist = fwList;
        },
        storeFwListPicWd(state, fwList) {
            for (let i = 0; i < fwList.length; i++) {
                fwList[i].deviceType = "PicWd";
            }
            state.fwListPicWd = fwList;
        },
        storeSelectedFw(state, fw) {
            state.selectedFw = fw;
        },
        storeCurrentChannel(state, channel) {
            state.currentChannel = channel;
        },
        createPbConn(state, pbData) {
            if (state.pb === null) {
                state.pb = new PubNub({
                    publishKey: pbData.pub,
                    subscribeKey: pbData.sub,
                    uuid: state.user
                });
                if (state.pb !== null) {
                    createPbListeners(state.pb);
                }
            }
        },
        setCurDevType(state, devType) {
            state.curDevType = devType;
        },
        setAdminCustPerPage(state, perPage) {
            state.adminCustTablePerPage = perPage;
        },
        setAdminAsscPerPage(state, perPage) {
            state.adminAsscTablePerPage = perPage;
        },
        setAdminEmplPerPage(state, perPage) {
            state.adminEmplTablePerPage = perPage;
        },
        setAdminDevPerPage(state, perPage) {
            state.adminDevTablePerPage = perPage;
        },
        setAdminFwPerPage(state, perPage) {
            state.adminFwPerPage = perPage;
        },
        setAdminFwDevPerPage(state, perPage) {
            state.adminFwDevPerPage = perPage;
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        joinDeviceRoom({commit, state, dispatch}, devId) {
            commit('setActiveDevice', devId);
            if (state.pb === null) {
                dispatch('getPubSubKeys');
            }
            else {
                state.pb.subscribe({
                    channels: [devId + "_Room"],
                    withPresence: true
                });

                commit('storeCurrentChannel', devId + "_Room");

                setTimeout(() => {
                    state.pb.publish({
                        channel: devId + "_Room",
                        message: {
                            uid: state.user,
                            text: "ping"
                        }
                        // eslint-disable-next-line no-unused-vars
                    }, function (status, response) {
                        //Handle error here
                    });
                }, 1000);

                setTimeout(() => {
                    state.pb.publish({
                        channel: devId + "_Room",
                        message: {
                            uid: state.user,
                            text: "qMode"
                        }
                        // eslint-disable-next-line no-unused-vars
                    }, function (status, response) {
                        //Handle error here
                    });
                }, 2000);

                setTimeout(() => {
                    state.pb.publish({
                        channel: devId + "_Room",
                        message: {
                            uid: state.user,
                            text: "qStat"
                        }
                        // eslint-disable-next-line no-unused-vars
                    }, function (status, response) {
                        //Handle error here
                    });
                }, 3000);
            }
        },
        getPubSubKeys({commit}) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/pubsub').then(response => {
                    return response.json();
                }).then(res => {
                    commit('createPbConn', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        leaveDeviceRoom({state}, devId) {
            state.pb.unsubscribe({
                channels: [devId + "_Room"]
            });
        },
        // eslint-disable-next-line no-unused-vars
        sendSockMessage({state}, msg) {
            //console.log(state.currentChannel);
            let pubMsg = {}
            pubMsg.channel = state.currentChannel;
            msg.uid = state.user;
            pubMsg.message = msg;
            state.pb.publish(pubMsg
                // eslint-disable-next-line no-unused-vars
            , function(status, response) {
                //Handle error here
            });
        },
        // eslint-disable-next-line no-unused-vars
        setLogoutTimer({commit, dispatch}, expirationTime) {
            const logTimer = setTimeout(() => {
                dispatch('logout');
                alert('Session Time Has Expired');
                // this.$bvToast.toast('Session Time Has Expired', {
                //     title: 'Login Session',
                //     variant: 'danger',
                //     noAutoHide: true,
                //     solid: true
                // });
            }, expirationTime * 1000);
            commit('setLogoutTimer', logTimer);
        },
        // eslint-disable-next-line no-unused-vars
        login ({commit, dispatch}, authData) {
            Vue.http.post('users/login', {username: authData.username, password: authData.password})
                .then( response => {
                    return response.json();
                }).then(res => {
                commit('clearAuthData');
                localStorage.removeItem('userId');
                localStorage.removeItem('token');
                localStorage.removeItem('expirationDate');
                localStorage.removeItem('status');
                localStorage.removeItem('actStat');
                localStorage.removeItem('role');
                localStorage.removeItem('comp');
                localStorage.removeItem('user');
                Vue.http.headers.common['Authorization'] = "";
                commit('authUser', {
                    token: res.token,
                    userId: res.userId,
                    status: res.regStatus,
                    actStat: res.actStatus,
                    role: res.role,
                    comp: res.comp
                });
                commit('storeUser', authData.username);
                const now = new Date();
                const expirationDate = new Date(now.getTime() + res.expiresIn * 1000);
                Vue.http.headers.common['Authorization'] = "Bearer " + res.token;
                localStorage.setItem('token', res.token);
                localStorage.setItem('userId', res.userId);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('status', res.regStatus);
                localStorage.setItem('actStat', res.actStatus);
                localStorage.setItem('role', res.role);
                localStorage.setItem('comp', res.comp);
                localStorage.setItem('user', authData.username);
                const custTblPerPage = localStorage.getItem('custTblPerPage');
                if (custTblPerPage !== null)
                    commit('setAdminCustPerPage', custTblPerPage);
                const asscTblPerPage = localStorage.getItem('asscTblPerPage');
                if (asscTblPerPage !== null)
                    commit('setAdminAsscPerPage', asscTblPerPage);
                const emplTblPerPage = localStorage.getItem('emplTblPerPage');
                if (emplTblPerPage !== null)
                    commit('setAdminEmplPerPage', emplTblPerPage);
                const devTblPerPage = localStorage.getItem('devTblPerPage');
                if (devTblPerPage !== null)
                    commit('setAdminDevPerPage', devTblPerPage);
                const fwTblPerPage = localStorage.getItem('fwTblPerPage');
                if (fwTblPerPage !== null)
                    commit('setAdminFwPerPage', fwTblPerPage);
                const fwDevTblPerPage = localStorage.getItem('fwDevTblPerPage');
                if (fwDevTblPerPage !== null)
                    commit('setAdminFwDevPerPage', fwDevTblPerPage);

                dispatch('setLogoutTimer', res.expiresIn);
                //console.log(res.regStatus);
                if (res.regStatus === 4) {
                    router.push('/dashboard');
                } else if (res.regStatus === 1) {
                    // this.$bvToast.toast('Need to Verify Your Email First', {
                    //     title: 'Login',
                    //     variant: 'danger',
                    //     solid: true
                    // });
                    alert("Need to Verify Your Email Before You Can Login");
                    dispatch('logout');
                }
                else if (res.regStatus === 2) {
                    router.push('/changepass');
                }
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                alert('Username or Password is Incorrect');
                // this.$bvToast.toast('Username or Password is Incorrect', {
                //     title: 'Login',
                //     variant: 'danger',
                //     solid: true
                // });
            })
        },
        // eslint-disable-next-line no-unused-vars
        updateLogin ({commit, dispatch}, authData) {
            commit('authUser', {
                token: authData.token,
                userId: authData.userId,
                status: authData.regStatus,
                actStat: authData.actStatus,
                role: authData.role,
                comp: authData.comp
            });
            commit('storeUser', authData.username);
            const now = new Date();
            const expirationDate = new Date(now.getTime() + authData.expiresIn * 1000);
            Vue.http.headers.common['Authorization'] = "Bearer " + authData.token;
            localStorage.setItem('token', authData.token);
            localStorage.setItem('userId', authData.userId);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('status', authData.regStatus);
            localStorage.setItem('actStat', authData.actStatus);
            localStorage.setItem('role', authData.role);
            localStorage.setItem('comp', authData.comp);
            localStorage.setItem('user', authData.username);
            const custTblPerPage = localStorage.getItem('custTblPerPage');
            if (custTblPerPage !== null)
                commit('setAdminCustPerPage', custTblPerPage);
            const asscTblPerPage = localStorage.getItem('asscTblPerPage');
            if (asscTblPerPage !== null)
                commit('setAdminAsscPerPage', asscTblPerPage);
            const emplTblPerPage = localStorage.getItem('emplTblPerPage');
            if (emplTblPerPage !== null)
                commit('setAdminEmplPerPage', emplTblPerPage);
            const devTblPerPage = localStorage.getItem('devTblPerPage');
            if (devTblPerPage !== null)
                commit('setAdminDevPerPage', devTblPerPage);
            const fwTblPerPage = localStorage.getItem('fwTblPerPage');
            if (fwTblPerPage !== null)
                commit('setAdminFwPerPage', fwTblPerPage);
            const fwDevTblPerPage = localStorage.getItem('fwDevTblPerPage');
            if (fwDevTblPerPage !== null)
                commit('setAdminFwDevPerPage', fwDevTblPerPage);
            dispatch('setLogoutTimer', authData.expiresIn);
            //console.log(res.regStatus);
            if (authData.regStatus === 4) {
                router.push('/dashboard');
            } else if (authData.regStatus === 1) {
                alert('Need to Verify Your Email Before You Can Login');
                // this.$bvToast.toast('Need to Verify Your Email Before You Can Login', {
                //     title: 'Login',
                //     variant: 'danger',
                //     solid: true
                // });
                dispatch('logout');
            }
        },
        tryAutoLogin ({commit, dispatch}) {
            const token = localStorage.getItem('token')
            if (!token) {
                return;
            }
            const expirationDate = localStorage.getItem('expirationDate');
            const now = new Date();
            if (now >= expirationDate) {
                return;
            }
            const expiresIn = (new Date(expirationDate) - now) / 1000;
            const userId = localStorage.getItem('userId');
            const status = parseInt(localStorage.getItem('status'));
            const actStat = parseInt(localStorage.getItem('actStat'));
            const role = parseInt(localStorage.getItem('role'));
            const comp = parseInt(localStorage.getItem('comp'));
            const username = localStorage.getItem('user');
            const custTblPerPage = localStorage.getItem('custTblPerPage');
            if (custTblPerPage !== null)
                commit('setAdminCustPerPage', custTblPerPage);
            const asscTblPerPage = localStorage.getItem('asscTblPerPage');
            if (asscTblPerPage !== null)
                commit('setAdminAsscPerPage', asscTblPerPage);
            const emplTblPerPage = localStorage.getItem('emplTblPerPage');
            if (emplTblPerPage !== null)
                commit('setAdminEmplPerPage', emplTblPerPage);
            const devTblPerPage = localStorage.getItem('devTblPerPage');
            if (devTblPerPage !== null)
                commit('setAdminDevPerPage', devTblPerPage);
            const fwTblPerPage = localStorage.getItem('fwTblPerPage');
            if (fwTblPerPage !== null)
                commit('setAdminFwPerPage', fwTblPerPage);
            const fwDevTblPerPage = localStorage.getItem('fwDevTblPerPage');
            if (fwDevTblPerPage !== null)
                commit('setAdminFwDevPerPage', fwDevTblPerPage);
            Vue.http.headers.common['Authorization'] = "Bearer " + token;
            commit('authUser', {token: token, userId: userId, status: status, actStat: actStat, role: role, comp: comp});
            commit('storeUser', username);
            dispatch('setLogoutTimer', expiresIn);
            if (status === 2) {
                router.replace('/changepass');
            }
            else {
                // eslint-disable-next-line no-unused-vars
                router.replace('/dashboard').then(result => {
                    //console.log(result);
                    // eslint-disable-next-line no-unused-vars
                }).catch(err => {
                    //console.log(err);
                });
            }
        },
        logout ({commit}) {
            commit('clearAuthData');
            commit('clearAdminUserData');
            commit('clearDeviceData');
            localStorage.removeItem('userId');
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('status');
            localStorage.removeItem('actStat');
            localStorage.removeItem('role');
            localStorage.removeItem('user');
            localStorage.removeItem('comp');
            Vue.http.headers.common['Authorization'] = "";
            router.replace('/');
        },
        // eslint-disable-next-line no-unused-vars
        storeUser ({commit, state}, userData) {
            if (!state.idToken) {
                router.replace('/signin');
            }
        },
        changePassword({dispatch}, passChange) {
            return new Promise((resolve, reject) => {
                Vue.http.post('users/changePass', passChange).then(response => {
                    return response.json();
                }).then(authData => {
                    console.log(authData);
                    dispatch('updateLogin', authData);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        updateAdminCustPerPage({commit}, perPage) {
            localStorage.setItem('custTblPerPage', perPage);
            commit('setAdminCustPerPage', perPage);
        },
        updateAdminAsscPerPage({commit}, perPage) {
            localStorage.setItem('asscTblPerPage', perPage);
            commit('setAdminAsscPerPage', perPage);
        },
        updateAdminEmplPerPage({commit}, perPage) {
            localStorage.setItem('emplTblPerPage', perPage);
            commit('setAdminEmplPerPage', perPage);
        },
        updateAdminDevPerPage({commit}, perPage) {
            localStorage.setItem('devTblPerPage', perPage);
            commit('setAdminDevPerPage', perPage);
        },
        updateAdminFwPerPage({commit}, perPage) {
            localStorage.setItem('fwTblPerPage', perPage);
            commit('setAdminFwPerPage', perPage);
        },
        updateAdminFwDevPerPage({commit}, perPage) {
            localStorage.setItem('fwDevTblPerPage', perPage);
            commit('setAdminFwDevPerPage', perPage);
        },
        getUserDeviceList({commit}) {
            Vue.http.get('users/dev/list').then( response => {
                return response.json();
            }).then(res => {
                // eslint-disable-next-line no-prototype-builtins
                if (res.hasOwnProperty('deviceList')) {
                    if (res.deviceList.length > 0) {
                        commit('storeDevices', res.deviceList);
                    }
                }
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                // No Devices or Error
                commit('storeDevices', []);
            });
        },
        getAdminUserList({commit}) {
            Vue.http.get('users/list').then( response => {
                return response.json();
            }).then(res => {
                commit('setAdminUserList', res);
                // eslint-disable-next-line no-unused-vars
            }).catch(err => {
                // No Devices or Error
                commit('setAdminUserList', []);
            });
        },
        getAdminDevList({commit}) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/list').then(response => {
                    return response.json();
                }).then(res => {
                    commit('setAdminDevList', res);
                    resolve();
                    // eslint-disable-next-line no-unused-vars
                }).catch(err => {
                    // No Devices or Error
                    commit('setAdminDevList', []);
                    reject();
                });
            });
        },
        getDeviceInfo({commit}, devId) {
            Vue.http.get('udev/info?id=' + devId).then(response => {
                return response.json();
            }).then(res => {
                commit('storeDeviceInfo', res);
            }).catch(err => {
                console.log(err);
            });
        },
        setDeviceInfo({commit}, reqData) {
            Vue.http.post('udev/info?id=' + reqData.id + "&userId=" + reqData.userId, reqData.tankFill).then(response => {
                return response.json();
            }).then(res => {
                commit('storeDeviceInfo', res);
            }).catch(err => {
                console.log(err);
            });
        },
        getOtherDevInfo({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/info?id=' + devReq.id + "&userId=" + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeOtherDevInfo', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        addDeviceToUser({commit}, reqData) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/add-device?setup=1', reqData).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceCfg({commit}, devId) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/cfg?id=' + devId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceCfg', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceCfgOther({commit}, reqData) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/cfg?id=' + reqData.devId + '&userId=' + reqData.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceCfg', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceCfg({commit}, cfgData) {
            return new Promise((resolve, reject) => {
                Vue.http.post('udev/cfg?id=' + cfgData.id + '&userId=' + cfgData.userId, cfgData.cfg).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSys({commit}, devId) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sys?id=' + devId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceSys', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSysOther({commit}, reqData) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sys?id=' + reqData.devId + '&userId=' + reqData.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceSys', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceSys({commit}, sysData) {
            return new Promise((resolve, reject) => {
                Vue.http.post('udev/sys?id=' + sysData.id + '&userId=' + sysData.userId, sysData.cfg).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getCompList({commit}) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/complist').then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeCompList', res.complist);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    commit('storeCompList', []);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        addCompList({commit}, compAdd) {
            return new Promise((resolve, reject) => {
                Vue.http.post('udev/complist', compAdd).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        editCompList({commit}, compEdit) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/complist', compEdit).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        deleteCompany({commit}, compName) {
            return new Promise((resolve, reject) => {
                Vue.http.delete('udev/complist?name=' + compName).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSchedule({commit}, devId) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sched?id=' + devId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceSched', res.sch);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceScheduleOther({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sched?id=' + devReq.id + '&userId=' + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceSched', res.sch);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceSchedule({commit}, payload) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/sched?id=' + payload.id, {sch: payload.sch}).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceScheduleOther({commit}, payload) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/sched?id=' + payload.id + '&userId=' + payload.userId, {sch: payload.sch}).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSchedStatus({commit}, devId) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sched/status?id=' + devId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('setSchedSyncStatus', {stat: res});
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSchedStatusOther({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sched/status?id=' + devReq.id + '&userId=' + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('setSchedSyncStatus', {stat: res});
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSuspend({commit}, devId) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sus?id=' + devId).then(response => {
                    return response.json();
                }).then(res => {
                    //console.log(res.sus);
                    commit('storeDeviceSus', res.sus);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSuspendOther({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sus?id=' + devReq.id + '&userId=' + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    //console.log(res.sus);
                    commit('storeDeviceSus', res.sus);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceSuspend({commit}, payload) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/sus?id=' + payload.id, {sus: payload.sus}).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceSuspendOther({commit}, payload) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/sus?id=' + payload.id + '&userId=' + payload.userId, {sus: payload.sus}).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSusStatus({commit}, devId) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sus/status?id=' + devId).then(response => {
                    return response.json();
                }).then(res => {
                    //console.log(res);
                    commit('setSusSyncStatus', {stat: res});
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceSusStatusOther({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/sus/status?id=' + devReq.id + '&userId=' + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    //console.log(res);
                    commit('setSusSyncStatus', {stat: res});
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceGeo({commit}, devId) {
            Vue.http.get('ugeo/loc?id=' + devId).then(response => {
                return response.json();
            }).then(res => {
                commit('storeDeviceGeo', res);
            }).catch(err => {
                console.log(err);
            });
        },
        // eslint-disable-next-line no-unused-vars
        verifyDeviceAddr({commit}, geoData) {
            return new Promise((resolve, reject) => {
                Vue.http.put('ugeo/loc/verify', geoData).then(response => {
                    return response.json();
                    // eslint-disable-next-line no-unused-vars
                }).then(res => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        getGeoLocResults({commit}, geoData) {
            return new Promise((resolve, reject) => {
                Vue.http.put('ugeo/loc/results', geoData).then(response => {
                    return response.json();
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        setDeviceGeo({commit}, geoData) {
            return new Promise((resolve, reject) => {
                Vue.http.post('ugeo/loc', geoData).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceGeo', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        setDeviceGeoGps({commit}, geoData) {
            return new Promise((resolve, reject) => {
                Vue.http.post('ugeo/loc/gps', geoData).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceGeo', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getAdminDeviceGeo({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('ugeo/loc?id=' + devReq.id + "&userId=" + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeAdminSelectedGeo', res);
                    resolve();
                }).catch(err => {
                    commit('storeAdminSelectedGeo', []);
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceUsers({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('users/dev/guest/list?id=' + devReq.id).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceUsers', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        remDeviceGuestUser({commit}, reqData) {
            return new Promise((resolve, reject) => {
                //console.log(reqData);
                Vue.http.delete('users/rem-guest-dev' + '?usr=' + reqData.username + '&id=' + reqData.id).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        addDeviceGuestUser({commit}, reqData) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/add-guest-dev', reqData).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceActivityLog({commit}, params) {
            Vue.http.get('act?id=' + params.devId + "&sd=" + params.startDate + "&ed=" + params.endDate).then(response => {
                return response.json();
            }).then(res => {
                commit('storeActivityLog', res.data);
                commit('setActSync', true);
                commit('setRefreshActivity', true);
            }).catch(err => {
                console.log(err);
            });
        },
        getDeviceActivityLogOther({commit}, params) {
            Vue.http.get('act?id=' + params.devId + '&userId=' + params.userId + "&sd=" + params.startDate + "&ed=" + params.endDate).then(response => {
                return response.json();
            }).then(res => {
                commit('storeActivityLog', res.data);
                commit('setActSync', true);
                commit('setRefreshActivity', true);
            }).catch(err => {
                console.log(err);
            });
        },
        getDetailActivityLog({commit}, params) {
            return new Promise((resolve, reject) => {
                Vue.http.get('act?id=' + params.devId + "&sd=" + params.startDate + "&ed=" + params.endDate).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeActivityDetailLog', res.data);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDetailActivityLogOther({commit}, params) {
            return new Promise((resolve, reject) => {
                Vue.http.get('act?id=' + params.devId + '&userId=' + params.userId + '&sd=' + params.startDate + '&ed=' + params.endDate).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeActivityDetailLog', res.data);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        checkDeviceSync({commit, state}) {
            let syncCount = 0;
            if (state.cfg !== null)
                syncCount++;
            if (state.sys !== null)
                syncCount++;
            if (state.compList !== null)
                syncCount++;
            if (state.devInfo !== null)
                syncCount++;
            if (state.sched !== null)
                syncCount++;
            if (state.sus !== null)
                syncCount++;
            if (state.geo !== null)
                syncCount++;

            if (syncCount === 7)
                commit('setDeviceSync', true);
        },
        checkDeviceSyncOther({commit, state}) {
            let syncCount = 0;
            if (state.cfg !== null)
                syncCount++;
            if (state.sys !== null)
                syncCount++;
            if (state.compList !== null)
                syncCount++;
            if (state.otherDevInfo !== null)
                syncCount++;
            if (state.sched !== null)
                syncCount++;
            if (state.sus !== null)
                syncCount++;
            if (state.aSelectedGeo !== null)
                syncCount++;

            if (syncCount === 7)
                commit('setDeviceSync', true);
        },
        checkActSync({commit, state}) {
            if (state.activityLog !== null) {
                commit('setActSync', true);
            }
        },
        // eslint-disable-next-line no-unused-vars
        deleteDevice({commit, state}, delDevId) {
            return new Promise((resolve, reject) => {
                Vue.http.delete('users/rem-device?id=' + delDevId).then(response => {
                    return response.json();
                    // eslint-disable-next-line no-unused-vars
                }).then(res => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        removeDeviceUser({commit, state}, remDevId) {
            return new Promise((resolve, reject) => {
                Vue.http.delete('users/rem-user-dev?id=' + remDevId).then(response => {
                    return response.json();
                    // eslint-disable-next-line no-unused-vars
                }).then(res => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        deleteUser({commit, state}, delReq) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/delete', delReq).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        createUser({commit, state}, newUser) {
            return new Promise((resolve, reject) => {
                Vue.http.post('users/create', newUser).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        changeUserCompany({commit, state}, userComp) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/comp', userComp).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        changeUserRole({commit, state}, userRole) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/role', userRole).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        changeUserReg({commit, state}, userReg) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/reg', userReg).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        changeUserStat({commit, state}, userStat) {
            return new Promise((resolve, reject) => {
                Vue.http.put('users/stat', userStat).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        getDeviceLogs({commit, state}, devReq) {
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line no-prototype-builtins
                if (devReq.hasOwnProperty("sd")) {
                    Vue.http.get('log?id=' + devReq.id + '&userId=' + devReq.userId + '&n=' + devReq.results + '&sd=' + devReq.sd + '&ed=' + devReq.ed).then(response => {
                        return response.json();
                    }).then(res => {
                        commit('storeDevLogs', res);
                        resolve();
                    }).catch(err => {
                        console.log(err);
                        commit('storeDevLogs', {data: []});
                        reject();
                    });
                }
                else {
                    Vue.http.get('log?id=' + devReq.id + '&userId=' + devReq.userId + '&n=' + devReq.results).then(response => {
                        return response.json();
                    }).then(res => {
                        commit('storeDevLogs', res);
                        resolve();
                    }).catch(err => {
                        console.log(err);
                        commit('storeDevLogs', {data: []});
                        reject();
                    });
                }
            });
        },
        getTemplateSchedules({commit}) {
            return new Promise((resolve, reject) => {
                Vue.http.get('template/sch').then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeTemplateSchedules', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    commit('storeTemplateSchedules', []);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        restoreDeviceSettings({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/restore?id=' + devReq.deviceId + "&mode=" + devReq.mode).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getCurDevFwVer({commit}, devReq) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/fw?id=' + devReq.id + '&userId=' + devReq.userId).then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeCurDevFwVer', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    commit('storeCurDevFwVer', null);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        saveTemplate({commit}, tempSch) {
            return new Promise((resolve, reject) => {
                Vue.http.post('template/sch', tempSch).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        editTemplateName({commit}, tempSch) {
            return new Promise((resolve, reject) => {
                Vue.http.patch('template/sch', tempSch).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        deleteTemplate({commit}, schName) {
            return new Promise((resolve, reject) => {
                Vue.http.delete('template/sch?name=' + schName).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        swapDeviceUser({commit}, swapReq) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/swap', swapReq).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getDeviceFwList({commit}) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/fw/all').then(response => {
                    return response.json();
                }).then(res => {
                    commit('storeDeviceFwList', res);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        getFwList({commit}, devType) {
            return new Promise((resolve, reject) => {
                Vue.http.get('udev/fw/list?devType=' + devType).then(response => {
                    return response.json();
                }).then(res => {
                    if (devType === "InsectControl")
                        commit('storeFwListInsect', res.fwList);
                    else if (devType === "CoolMist")
                        commit('storeFwListCoolMist', res.fwList);
                    else if (devType === "PicWd")
                        commit('storeFwListPicWd', res.fwList);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        addNewFwVersion({commit}, newFW) {
            return new Promise((resolve, reject) => {
                Vue.http.post('udev/fw', newFW).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        deleteFwVersion({commit}, fwVerInfo) {
            return new Promise((resolve, reject) => {
                Vue.http.delete('udev/fw?verNum=' + fwVerInfo.fwVerNum + '&devType=' + fwVerInfo.devType).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setDeviceFwAction({commit}, fwReq) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/fw?id=' + fwReq.deviceId, fwReq).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        },
        // eslint-disable-next-line no-unused-vars
        setPicFwAction({commit}, fwReq) {
            return new Promise((resolve, reject) => {
                Vue.http.put('udev/pic/fw?id=' + fwReq.deviceId, fwReq).then(response => {
                    return response.json();
                }).then(() => {
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
            });
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        role(state) {
          return state.role;
        },
        company(state) {
            return state.comp
        },
        isAuthenticated (state) {
            return state.idToken !== null;
        },
        isRegistered (state) {
            return state.status !== null;
        },
        regStatus(state) {
          return state.status;
        },
        actStatus(state) {
            return state.actStat;
        },
        devices(state) {
            return state.devices;
        },
        deviceCfg (state) {
            return state.cfg;
        },
        deviceSys (state) {
            return state.sys;
        },
        compList (state) {
          return state.compList;
        },
        compTemplateSel (state) {
          return state.aSelectCompTemplate;
        },
        deviceInfo (state) {
            return state.devInfo;
        },
        otherDevInfo (state) {
            return state.otherDevInfo;
        },
        deviceUsers (state) {
          return state.devUsers;
        },
        deviceSched (state) {
            return state.sched;
        },
        deviceSus (state) {
            return state.sus;
        },
        deviceGeo (state) {
            return state.geo;
        },
        activityLog (state) {
            return state.activityLog;
        },
        activityDetailLog (state) {
          return state.activityDetailLog;
        },
        isDeviceSync (state) {
            return state.devSync;
        },
        isActSync (state) {
            return state.actSync;
        },
        getSchedSyncTime (state) {
            return state.schedSyncTime;
        },
        getSchedSyncCode (state) {
            return state.schedSyncCode;
        },
        getSusSyncTime (state) {
            return state.susSyncTime;
        },
        getSusSyncCode (state) {
            return state.susSyncCode;
        },
        isDeviceOnline (state) {
            return state.deviceOnline;
        },
        getActiveDevice (state) {
            return state.activeDevice;
        },
        getLastDevicePingTime (state) {
            return state.lastDevicePing;
        },
        isSpraying(state) {
            return state.sprayStatus;
        },
        refreshActivityState(state) {
            return state.refreshActivity;
        },
        getDeviceSysMode(state) {
            return state.devInfo.mode;
        },
        getDeviceSysModeOther(state) {
            return state.otherDevInfo.mode;
        },
        getAdminCustPerPage(state) {
          return state.adminCustTablePerPage
        },
        getAdminAsscPerPage(state) {
            return state.adminAsscTablePerPage
        },
        getAdminEmplPerPage(state) {
            return state.adminEmplTablePerPage
        },
        getAdminDevPerPage(state) {
            return state.adminDevTablePerPage
        },
        getAdminFwPerPage(state) {
            return state.adminFwPerPage
        },
        getAdminFwDevPerPage(state) {
            return state.adminFwDevPerPage
        },
        adminUserList(state) {
            return state.userData;
        },
        employeeList(state) {
            return state.employeeData;
        },
        associateList(state) {
            return state.associateData;
        },
        customerList(state) {
            return state.customerData;
        },
        adminDevList(state) {
            return state.devData;
        },
        deviceLogs(state) {
            return state.devLogs;
        },
        adminSelectedUser(state) {
            return state.aSelectedUser;
        },
        adminSelectedDev(state) {
            return state.aSelectedDev;
        },
        adminSelectedGeo(state) {
            return state.aSelectedGeo;
        },
        templateSchedules(state) {
            return state.templateSchedules;
        },
        selectedTemplates(state) {
            return state.selectedTemplateSchedules;
        },
        curDevFwVer(state) {
            return state.curDevFwVer;
        },
        lastSocketCommand(state) {
            return state.lastSocketCmd;
        },
        swapDevOld(state) {
            return state.swapDevOld;
        },
        swapDevNew(state) {
            return state.swapDevNew;
        },
        deviceFwList(state) {
            return state.deviceFwList;
        },
        selectedDeviceFw(state) {
            return state.selectedDevFw;
        },
        fwListInsect(state) {
            return state.fwListInsect;
        },
        fwListCoolMist(state) {
            return state.fwListCoolMist;
        },
        fwListPicWd(state) {
            return state.fwListPicWd;
        },
        selectedFw(state) {
            return state.selectedFw;
        },
        curChannel(state) {
            return state.currentChannel;
        },
        isPb(state) {
            return state.pb !== null;
        },
        getCurDevType(state) {
            return state.curDevType;
        }
    }
});

function createPbListeners(pb) {
    pb.addListener({
        message: function (event) {

            //console.log(event.message.text);
            if (event.message.uid === store.getters.getActiveDevice &&
                event.message.text === "Pong") {
                store.commit('setLastDevicePing', Date.now());
                store.commit('setDeviceOnlineStatus', true);
                store.commit('storeLastSocketCmd', 'Ping Response');
            } else if (event.message.text.search('Started Spray') !== -1) {
                store.commit('setSprayStatus', true);
                store.commit('storeLastSocketCmd', 'Spray Started');
            } else if (event.message.text.search('Running') !== -1) {
                store.commit('setSprayStatus', true);
                store.commit('storeLastSocketCmd', 'Status Update');
            } else if (event.message.text.search('Idle') !== -1) {
                store.commit('setSprayStatus', false);
                store.commit('storeLastSocketCmd', 'Status Update');
            } else if (event.message.text.search('Stopped') !== -1) {
                store.commit('setSprayStatus', false);
                setTimeout(function () {
                    //console.log('Refresh Device after job');
                    store.dispatch('getDeviceInfo', store.getters.getActiveDevice);
                    let startDate = new Date();
                    let endDate = new Date();
                    startDate.setHours(0, 0, 0, 0);
                    startDate.setDate(1);
                    endDate.setHours(0, 0, 0, 0);
                    endDate.setDate(endDate.getDate() + 1);
                    store.dispatch('getDeviceActivityLog', {
                        devId: store.getters.getActiveDevice,
                        startDate: Math.round(startDate.getTime() / 1000),
                        endDate: Math.round(endDate.getTime() / 1000)
                    });
                }, 5000);
                store.commit('storeLastSocketCmd', 'Spray Stopped By User');
            } else if (event.message.text.search('Done Spray') !== -1) {
                store.commit('setSprayStatus', false);
                setTimeout(function () {
                    //console.log('Refresh Device after job');
                    store.dispatch('getDeviceInfo', store.getters.getActiveDevice);
                    let startDate = new Date();
                    let endDate = new Date();
                    startDate.setHours(0, 0, 0, 0);
                    startDate.setDate(1);
                    endDate.setHours(0, 0, 0, 0);
                    endDate.setDate(endDate.getDate() + 1);
                    store.dispatch('getDeviceActivityLog', {
                        devId: store.getters.getActiveDevice,
                        startDate: Math.round(startDate.getTime() / 1000),
                        endDate: Math.round(endDate.getTime() / 1000)
                    });
                }, 5000);
                store.commit('storeLastSocketCmd', 'Spray Finished');
            } else if (event.message.text.search('Mode') !== -1) {
                let sysMode = parseInt(event.message.text.split(" ")[1]);
                store.commit('setDeviceMode', sysMode);
                store.commit('storeLastSocketCmd', 'Mode Change');
            }
        },
        presence: function (event) {
            //console.log(event.action + " : " + event.uuid);
            if (event.action === "leave") {
                if (event.uuid === store.getters.getActiveDevice) {
                    store.commit('setDeviceOnlineStatus', false);
                    store.commit('setSprayStatus', false);
                    store.commit('storeLastSocketCmd', 'Device Went Offline');
                }
            } else if (event.action === "join") {
                if (event.uuid === store.getters.getActiveDevice) {
                    setTimeout(() => {
                        store.dispatch('sendSockMessage', {text: "ping"});
                    }, 500);

                    setTimeout(() => {
                        store.dispatch('sendSockMessage', {text: "qMode"});
                    }, 1500);

                    setTimeout(() => {
                        store.dispatch('sendSockMessage', {text: "qStat"});
                    }, 3000);

                    // setTimeout(function () {
                    //     //console.log('Refresh Device after offline');
                    //     store.dispatch('getDeviceInfo', store.getters.getActiveDevice);
                    //     let startDate = new Date();
                    //     let endDate = new Date();
                    //     startDate.setHours(0, 0, 0, 0);
                    //     startDate.setDate(1);
                    //     endDate.setHours(0, 0, 0, 0);
                    //     endDate.setDate(endDate.getDate() + 1);
                    //     store.dispatch('getDeviceActivityLog', {
                    //         devId: store.getters.getActiveDevice,
                    //         startDate: Math.round(startDate.getTime() / 1000),
                    //         endDate: Math.round(endDate.getTime() / 1000)
                    //     });
                    // }, 5000);
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        status: function (event) {
            //console.log(event.category + " : " + event.affectedChannels);
        }
    });
}

export default store;
