<template>
  <div class="resend">
    <b-form-group class="container">
      <b-card bg-variant="light">
        <h3 class="col-12 text-center">Resend Email</h3>
        <b-form-group>
          <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-4" label="Email" label-for="input-4">
              <b-form-input id="input-4" v-model="form.email" type="email" placeholder="Enter email" required></b-form-input>
              <b-form-invalid-feedback :state="emailCheck">
                Please enter a valid Email address
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="emailCheck">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
            <b-button class="col-12 text-center" type="submit" variant="primary">Submit</b-button>
          </b-form>
        </b-form-group>
      </b-card>
    </b-form-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  computed: {
    emailCheck() {
      return this.emailTest();
    }
  },
  methods: {
    onSubmit() {
      if (!this.emailTest()) {
          this.$bvToast.toast('Bad Email Address', {
              title: 'Signup Resend',
              variant: 'danger',
              solid: true
          });
        return;
      }
      console.log("Submit");
      this.$http.post('users/resend', this.form).then(response => {
        return response.json();
        // eslint-disable-next-line no-unused-vars
      }).then(res => {
        this.$router.replace('/signupdone');
        // eslint-disable-next-line no-unused-vars
      }).catch(err => {
          this.$bvToast.toast('Unrecognized Email Address', {
              title: 'Signup Resend',
              variant: 'danger',
              solid: true
          });
      });
    },
    emailTest() {
      if (this.form.email.length < 7) return false

      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
      if (!re.test(this.form.email)) {
        return false;
      } else {
        return true;
      }
    },
  }
}
</script>

<style scoped>
.container {
  background-color: #f3f3f3;
  color: #663399;
  padding: 15px 25px;
  width: 100%;
  max-width: 35rem;
  position: relative;
  transform: translate(0%, 5%);
  left: 0%;
  top: 50%;
}
</style>
