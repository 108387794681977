<template>
  <div>
    <b-col>
      <b-card
          class="mb-2"

      >
        <div>
          <b-button-group>
            <b-button size="lg" @click="minusClick">-</b-button>
              <b-button variant="success"> <h6>Next Scheduled Spray</h6><hr>
            <div>{{nextSpray}}</div>
            </b-button>
            <b-button size="lg" @click="plusClick">+</b-button>
          </b-button-group>
        </div>
      </b-card>
    </b-col>
  </div>
</template>

<script>
  import eTypes from '@/enumTypes';
  import store from "@/store";

  export default {
    name: "NextSpray",
    props: ['id'],
    data() {
        return {
            nextSpray: "",
            currentOffsetLookupTime: 0,
            syncInterval: null,
            updateCheckInterval: null,
            curNext: Math.round(Date.now() / 1000)
        }
    },
    created() {
        this.currentOffsetLookupTime = Math.round(Date.now() / 1000);
        this.syncInterval = setInterval(() => {
            if (this.$store.getters.isDeviceSync) {
                clearInterval(this.syncInterval);
                this.plusClick();
            }
        }, 1000);
        this.updateCheckInterval = setInterval(() => {
            const curDateTime = Math.round(Date.now() / 1000);
            //console.log(curDateTime + ' = ' + this.curNext);
            if (curDateTime >= this.curNext) {
                this.currentOffsetLookupTime = Math.round(Date.now() / 1000);
                this.plusClick();
                if (!this.$store.getters.isDeviceOnline) {
                    setTimeout(() => {
                        store.dispatch('getDeviceInfo', store.getters.getActiveDevice);
                        store.dispatch('getDeviceActivityLog', store.getters.getActiveDevice);
                    }, 60000);
                }
            }
        }, 60000);
    },
    destroyed() {
        if (this.syncInterval) {
          clearInterval(this.syncInterval);
        }
        if (this.updateCheckInterval) {
            clearInterval(this.updateCheckInterval);
        }
    },
    methods: {
      plusClick() {
        let nextSch = this.calcNext(1);
        if (nextSch !== -1) {
            this.nextSpray = this.moment(nextSch * 1000).format("dddd, MM/DD/YYYY h:mm A");
            this.curNext = nextSch;
        }
      },
      minusClick() {
          let nextSch = this.calcNext(-1);
          if (nextSch !== -1) {
              this.nextSpray = this.moment(nextSch * 1000).format("dddd, MM/DD/YYYY h:mm A");
              this.curNext = nextSch;
          }
      },
        getLocalOffset(timestamp, altOffset) {
            const geoData = this.$store.getters.deviceGeo;
            let totalOffset = geoData.gmtOffset;
            let orgDst = this.moment(timestamp * 1000).isDST();
            let curDst = this.moment().isDST();
            let useLocal = false;
            if (Math.abs(Math.abs(altOffset) - Math.abs(geoData.gmtOffset)) > 1) {
                altOffset = totalOffset;
                useLocal = true;
            }
            if (altOffset !== 0) {
                if (altOffset !== geoData.gmtOffset) {
                    totalOffset = geoData.gmtOffset - (geoData.gmtOffset - altOffset);
                }
            }
            if (orgDst !== curDst) {
                if (!orgDst && curDst)
                    totalOffset += 1;
                else if (orgDst && !curDst)
                    totalOffset -= 1;
            }
            if (useLocal) {
                timestamp += ((totalOffset * 60) * 60);
                let mn = this.moment(timestamp * 1000);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
            else {
                let mn = this.moment(timestamp * 1000).utcOffset(totalOffset * 60);
                let offsetTime = mn.valueOf();
                return offsetTime / 1000;
            }
        },
      calcNext(direction) {
        const sched = this.$store.getters.deviceSched;
        const sus = this.$store.getters.deviceSus;
        //const devInfo = this.$store.getters.deviceInfo;
        const geoData = this.$store.getters.deviceGeo;
        let lookAheadTimestamp = this.currentOffsetLookupTime;
        const notFound = true;
        let iterCount = 0;

        while (notFound) {
          if (direction === 1) { // Forward
            lookAheadTimestamp += 60;
          } else if (direction === -1) { // Reverse
            lookAheadTimestamp -= 60;
          }
          let start = 0;
          let end = sched.length;
          let schTimestamp = 0;

          if (lookAheadTimestamp < Math.round(Date.now() / 1000))
          {
              return -1;
          }
          for (let i = start; i < end; i++) {
              if (sched[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CUSTOM) {
                  schTimestamp = sched[i].time;
              }
              else if (sched[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CONTINUOUS) {
                  schTimestamp = sched[i].time;
              }
              else if (sched[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_CYCLE) {
                  schTimestamp = sched[i].time;
              }
              else if (sched[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNRISE) {
                  schTimestamp = geoData.sunrise;
                  sched[i].gmtOffset = geoData.gmtOffset;
              } else if (sched[i].startFlag === eTypes.enums.Event_Start_Time.EVENT_START_TIME_SUNSET) {
                  schTimestamp = geoData.sunset;
                  sched[i].gmtOffset = geoData.gmtOffset;
              }
              if (sched[i].startOffset !== 0) {
                  schTimestamp += sched[i].startOffset * 60;
              }
              const offsetTime = this.getLocalOffset(schTimestamp, sched[i].gmtOffset);

              let weekDayMatch = false;
              let weekdayNum = this.moment(lookAheadTimestamp * 1000).day();
              if (weekdayNum === 0 && (sched[i].daysFlag & eTypes.enums.Days.SUNDAY) === eTypes.enums.Days.SUNDAY)
                  weekDayMatch = true;
              if (weekdayNum === 1 && (sched[i].daysFlag & eTypes.enums.Days.MONDAY) === eTypes.enums.Days.MONDAY)
                  weekDayMatch = true;
              if (weekdayNum === 2 && (sched[i].daysFlag & eTypes.enums.Days.TUESDAY) === eTypes.enums.Days.TUESDAY)
                  weekDayMatch = true;
              if (weekdayNum === 3 && (sched[i].daysFlag & eTypes.enums.Days.WEDNESDAY) === eTypes.enums.Days.WEDNESDAY)
                  weekDayMatch = true;
              if (weekdayNum === 4 && (sched[i].daysFlag & eTypes.enums.Days.THURSDAY) === eTypes.enums.Days.THURSDAY)
                  weekDayMatch = true;
              if (weekdayNum === 5 && (sched[i].daysFlag & eTypes.enums.Days.FRIDAY) === eTypes.enums.Days.FRIDAY)
                  weekDayMatch = true;
              if (weekdayNum === 6 && (sched[i].daysFlag & eTypes.enums.Days.SATURDAY) === eTypes.enums.Days.SATURDAY)
                  weekDayMatch = true;

              if (weekDayMatch) {
                  let schHour = this.moment(offsetTime * 1000).hour();
                  let schMin = this.moment(offsetTime * 1000).minute();
                  let laHour = this.moment(lookAheadTimestamp * 1000).hour();
                  let laMin = this.moment(lookAheadTimestamp * 1000).minute();
                  if (schHour === laHour && schMin === laMin) {
                      // Check Suspension list
                      let isSuspended = false;
                      for (let j = 0; j < sus.length; j++) {
                          if (lookAheadTimestamp >= sus[j].sTime && lookAheadTimestamp <= sus[j].eTime) {
                              isSuspended = true;
                          }
                      }

                      // Check Skip Event List
                      // Not used for now
                      let isSkipped = false;

                      if (!isSuspended && !isSkipped) {
                          this.currentOffsetLookupTime = lookAheadTimestamp;
                          return lookAheadTimestamp;
                      }
                  }
              }
          }
          if (iterCount > 9999) {
              return -1;
          }
          iterCount++;
        }
      }
    }
  }
</script>

<style scoped>

</style>
