import { render, staticRenderFns } from "./ActivityDetails.vue?vue&type=template&id=ed7339c8&scoped=true&"
import script from "./ActivityDetails.vue?vue&type=script&lang=js&"
export * from "./ActivityDetails.vue?vue&type=script&lang=js&"
import style0 from "./ActivityDetails.vue?vue&type=style&index=0&id=ed7339c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed7339c8",
  null
  
)

export default component.exports