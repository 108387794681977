<template>
    <div>
        <b-col>
            <b-card
                class="mb-2"

                header-tag="header"
            >
                <template #header>
                    <h6>{{lastMistActivity}}</h6>
                    <b-container>
                        <b-row cols="20">
                            <b-col>
                                <b-container>
                                    <b-row cols="10">
                                        <b-col>
                                            <b-icon-diagram3-fill></b-icon-diagram3-fill>
                                            <div></div>
                                            <small>{{lastZone}}</small>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-col>
                            <b-col>
                                <b-container>
                                    <b-row cols="10">
                                        <b-col>
                                            <b-icon-clock-fill></b-icon-clock-fill>
                                            <div></div>
                                            <small>{{lastDur}}</small>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-col>
                            <b-col>
                                <b-container>
                                    <b-row cols="10">
                                        <b-col>
                                            <b-icon-bar-chart-line-fill></b-icon-bar-chart-line-fill>
                                            <div></div>
                                            <small>{{lastFlow}}</small>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-col>
                            <b-col>
                                <b-container>
                                    <b-row cols="10">
                                        <b-col>
                                            <b-icon-droplet-fill></b-icon-droplet-fill>
                                            <div></div>
                                            <small>{{lastGal}}</small>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-col>
                            <b-col>
                                <b-container>
                                    <b-row cols="10">
                                        <b-col>
                                            <b-icon-bezier2></b-icon-bezier2>
                                            <div></div>
                                            <small>{{lastPulse}}</small>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
                <div class="chart-wrapper">
                    <chart :options="option" class="echarts" autoresize></chart>
                    <b-button size="sm" @click="activityDetails" variant="outline-primary">
                        Activity Details
                    </b-button>
                </div>
            </b-card>
        </b-col>
    </div>
</template>

<script>
import eTypes from '@/enumTypes';
export default {
    name: "ActivityGraph",
    props: ['id'],
    data() {
        return {
            syncInterval: null,
            flowData: [],
            primeData: [],
            pulseData: [],
            timeSteps: [],
            mostRecent: -1
        }
    },
    created() {
        this.syncInterval = setInterval(() => {
            if (this.getMostRecent() !== -1) {
                clearInterval(this.syncInterval);
                this.getMostRecent();
                this.getFlowData();
                this.getPulseData();
            }
        }, 1000);
    },
    methods: {
        activityDetails() {
            this.$router.push('/activity/' + this.id);
        },
        getFlowData() {
          if (this.mostRecent !== -1) {
              const flowTemp = this.$store.getters.activityLog[this.mostRecent].flow;
              this.flowData = [];
              this.primeData = [];
              const dur = this.$store.getters.activityLog[this.mostRecent].dur;
              this.timeSteps = [];
              const tickStep = dur / 30;
              let tickLabel = 0;
              for (let i = 0; i < 30; i++) {
                  if (i < 6) {
                      this.primeData.push(flowTemp[i]);
                      this.flowData.push('-');
                  } else if (i === 6) {
                      this.primeData.push(flowTemp[i]);
                      this.flowData.push(flowTemp[i]);
                  } else {
                      this.flowData.push(flowTemp[i]);
                  }
                  if (i === 29)
                      tickLabel += tickStep;
                  this.timeSteps.push(tickLabel.toFixed(1));
                  tickLabel += tickStep;
              }
          }
      },
      getPulseData() {
          if (this.mostRecent !== -1)
            this.pulseData = this.$store.getters.activityLog[this.mostRecent].pulse;
      },
      getMostRecent() {
          const act = this.$store.getters.activityLog;
          if (act) {
              for (let i = 0; i < act.length; i++) {
                  if (act[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NORMAL ||
                      act[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_CLOG ||
                      act[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LEAK ||
                      act[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_NO_FLOW ||
                      act[i].status === eTypes.enums.Activity_Status.SM_ACTIVITY_STATUS_LOW) {
                      this.mostRecent = i;
                      break;
                  }
              }
          }
      }
    },
    destroyed() {
        if (this.syncInterval) {
            clearInterval(this.syncInterval);
        }
    },
    computed: {
        lastMistActivity() {
            const act = this.$store.getters.activityLog;
            if (act) {
                if (this.mostRecent !== -1) {
                    let dateStr = this.moment(act[this.mostRecent].timestamp * 1000).format("MMM D, YYYY");
                    let timeStr = this.moment(act[this.mostRecent].timestamp * 1000).format("h:mm A");
                    this.getFlowData();
                    this.getPulseData();
                    return 'Last Mist - ' + dateStr + ' @ ' + timeStr;
                }
            }

            return "---"
        },
        lastZone() {
            const act = this.$store.getters.activityLog;
            if (this.mostRecent === -1)
                this.getMostRecent();

            if (this.mostRecent !== -1) {
                if (act) {
                    return act[this.mostRecent].zone;
                }
            }
            return '';
        },
        lastDur() {
            const act = this.$store.getters.activityLog;
            if (this.mostRecent === -1)
                this.getMostRecent();

            if (this.mostRecent !== -1) {
                if (act) {
                    return act[this.mostRecent].dur + 's';
                }
            }
            return '';
        },
        lastGal() {
            const act = this.$store.getters.activityLog;
            if (this.mostRecent === -1)
                this.getMostRecent();

            if (this.mostRecent !== -1) {
                if (act) {
                    return act[this.mostRecent].gal.toFixed(2);
                }
            }
            return '';
        },
        lastFlow() {
            const act = this.$store.getters.activityLog;
            if (this.mostRecent === -1)
                this.getMostRecent();

            if (this.mostRecent !== -1) {
                if (act) {
                    let flowAvg = 0;

                    for (let i = 0; i < act[this.mostRecent].flow.length; i++) {
                        flowAvg += act[this.mostRecent].flow[i];
                    }
                    flowAvg /= act[this.mostRecent].flow.length;
                    return flowAvg.toFixed(2);
                }
            }
            return '';
        },
        lastPulse() {
            const act = this.$store.getters.activityLog;
            if (this.mostRecent === -1)
                this.getMostRecent();

            if (this.mostRecent !== -1) {
                if (act) {
                    let pulseAvg = 0;
                    for (let i = 0; i < act[this.mostRecent].pulse.length; i++) {
                        pulseAvg += act[this.mostRecent].pulse[i];
                    }
                    pulseAvg /= act[this.mostRecent].pulse.length;
                    return Math.round(pulseAvg * act[this.mostRecent].dur);
                }
            }
            return '';
        },
        option() {
            return {
                xAxis: {
                    type: 'category',
                    splitLine: {
                      show: true,
                      interval: 4
                    },
                    axisLabel: {
                      show: true,
                      interval: 4,
                      showMaxLabel: true
                    },
                    axisTick: {
                      show: true,
                      interval: 4,
                      length: 6
                    },
                    max: 29,
                    data: this.timeSteps
                },
                yAxis: {
                    type: "value"
                },
                legend: {
                  data: ['Prime','Rate', 'Pulses']
                },
                series: [
                    {
                        name: 'Prime',
                        type: 'line',
                        symbolSize: 2,
                        data: this.primeData,
                        color: ["#e72020"],
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: ["#e72020C8"]
                                }, {
                                    offset: 1, color: ["#e7202020"]
                                }],
                                global: false // false by default
                            }
                        }
                    },
                    {
                        name: 'Rate',
                        type: 'line',
                        symbolSize: 2,
                        data: this.flowData,
                        color: ["#3990dd"],
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: ["#3990ddc8"]
                                }, {
                                    offset: 1, color: ["#3990dd20"]
                                }],
                                global: false // false by default
                            }
                        }
                    },
                    {
                        name: 'Pulses',
                        type: 'line',
                        symbolSize: 2,
                        data: this.pulseData,
                        color: ["#dd7f37"],
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: ["#dd7f37C8"]
                                }, {
                                    offset: 1, color: ["#dd7f3720"]
                                }],
                                global: false // false by default
                            }
                        }
                    }
                ],

            }
        }
    }
}
</script>

<style scoped>
html {
    scroll-behavior: smooth;
}
.chart-wrapper {
    width: 100%;
    height: 100%;
}
.echarts {
    width: 100%;
    height: 240px;
}

</style>
