<template>
  <div>
    <b-navbar toggleable="lg navbar-light bg-light" >
      <b-navbar-brand>
        <b-navbar-brand @click="checkLogin"><img src="../assets/SmartMist.jpg" height="24"></b-navbar-brand>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="onDashboard" v-if="auth">Dashboard</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
            <b-nav-item @click="onAdmin" v-if="userRole > roleList.roles.normalUser">Admin</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right v-if="auth">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em><b-icon icon="person-circle"></b-icon></em>
            </template>
            <b-dropdown-item @click="onSettings">Settings</b-dropdown-item>
            <b-dropdown-item @click="onLogout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import roles from '@/roles';

export default {
  data() {
    return {
      roleList: roles
    }
  },
  computed: {
    userRole() {
      return this.$store.getters.role;
    },
    auth() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('logout');
    },
    checkAuth() {
      return this.$store.getters.isAuthenticated;
    },
    checkLogin() {
      const curRoute = this.$router.currentRoute.path;
      if (this.checkAuth()) {
        if (curRoute !== '/dashboard') {
          this.$router.push('/dashboard');
        }
      } else {
        if (curRoute !== '/') {
          this.$router.push('/');
        }
      }
    },
    onDashboard() {
      const curRoute = this.$router.currentRoute.path;
      if (curRoute !== '/dashboard') {
        this.$router.push('/dashboard');
      }
    },
    onAdmin() {
    const curRoute = this.$router.currentRoute.path;
        if (curRoute !== '/admin') {
          this.$router.push('/admin');
        }
    },
    onSettings() {
      this.$router.push('/settings/user')
    }
  }
}
</script>

<style scoped>
.logout {
  background-color: transparent;
  border: none;
  font: inherit;
  color: black;
  cursor: pointer;
}
</style>
