<template>
    <div>
        <h3>Settings</h3>
        <div>
            Current User: {{currentUser}}
        </div>
<!--        <div>-->
<!--            Role: {{currentRole}}-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
  created() {
    if (!this.$store.state.idToken) {
      this.$router.push('/');
      return;
    }
    else {
        if (this.$store.getters.regStatus === 2) {
            this.$router.push('/changepass');
        }
    }
  },
    computed: {
        currentUser() {
          return this.$store.getters.user;
        },
        currentRole() {
          return this.$store.getters.role;
        }
    }
}
</script>

<style scoped>

</style>
