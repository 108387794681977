import Vue from 'vue';
import Echarts from 'vue-echarts/components/ECharts.vue';

import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip'

Vue.component('chart', Echarts);
