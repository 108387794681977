<template>
  <div>
    <div class="card-columns">
      <app-device v-for="(device, index) in devices" :device="device" v-bind:key="index"></app-device>
    </div>
  </div>
</template>

<script>
  import Device from "@/components/Device";
  export default {
    name: "Devices",
    components: {
      appDevice: Device
    },
    computed: {
      devices() {
        return this.$store.getters.devices;
      }
    }
  }
</script>

<style scoped>

</style>
