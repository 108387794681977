<template>
  <div>
    <h2>Password Reset Verification</h2>
    <p></p>
    <div v-if="!errVerify" >
      Initiating Password Reset
    </div>
    <div v-if="isVerified">
      You can reset your password now.
    </div>
    <div v-if="errVerify">
      Password reset error, restart the password reset process.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVerified: false,
      errVerify: false,
      resetKey: ''
    }
  },
  created() {
    this.checkPassReset();
  },
  methods: {
    checkPassReset() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.query.hasOwnProperty('reset')) {
        console.log(this.$route.query);
        this.$http.get('users/verifyReset?reset=' + this.$route.query.reset).then(response => {
          if (response.status === 200) {
            this.isVerified = true;
            this.resetKey = this.$route.query.reset;
            this.gotoResetPageTimeout();
          }
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.errVerify = true;
        });
      }
    },
    gotoResetPageTimeout() {
      const router = this.$router;
      const regKey = this.resetKey;
      setTimeout(function(){
        router.replace('/resetnewpass?reset=' + regKey);
      }, 2000);
    }
  }
}
</script>

<style scoped>

</style>
